import { Add } from "@features/context/add";
import { Copy } from "@features/context/copy";
import { Delete } from "@features/context/delete";
import { Edit } from "@features/context/edit";
import * as ContextMenu from "@radix-ui/react-context-menu";
import { Context as ContextProps } from "@shared/types";
import { columnModel } from "@widgets/column";
import { useUnit } from "effector-react";
import { FC } from "react";

import styles from "./styles.module.scss";

export const Context: FC<ContextProps> = ({
  columnName,
  deleteBlock,
  addBlock,
  model,
  copyBlock,
  editingIndex,
  setEditingIndex,
  subName,
}) => {
  const [selectedIndex] = useUnit([columnModel.$editingIndex]);
  const handleClick = () => {
    selectedIndex != null &&
      selectedIndex != editingIndex &&
      setEditingIndex &&
      setEditingIndex(null);
  };
  const handleContentClick = (event: any) => {
    event.stopPropagation();
  };
  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger
        className={styles.context_trigger}
        onClick={handleClick}
      />
      {(deleteBlock || addBlock || editingIndex) && (
        <ContextMenu.Portal>
          <ContextMenu.Content
            className={styles.context_content}
            onClick={handleContentClick}
          >
            {addBlock && <Add subName={subName} addBlock={addBlock} />}
            {deleteBlock && (
              <Delete
                columnName={columnName}
                deleteBlock={deleteBlock}
                model={model}
              />
            )}
            {editingIndex && (
              <Edit
                editingIndex={editingIndex}
                setEditingIndex={setEditingIndex}
              />
            )}
            {copyBlock && <Copy editingIndex={editingIndex} />}
          </ContextMenu.Content>
        </ContextMenu.Portal>
      )}
    </ContextMenu.Root>
  );
};
