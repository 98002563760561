export * as homeModel from "./model";
export { HomePage } from "./ui";

import { chainAuthorized } from "@shared/lib/route";

import { currentRoute } from "./model";
import { HomePage } from "./ui";

export const HomeRoute = {
  view: HomePage,
  route: chainAuthorized(currentRoute),
};
