export const handleAddBlock = (setFields?: any, fields?: any) => {
  fields &&
    setFields([
      ...fields.getState(),
      {
        type: "select",
        title: fields.getState().find((field: any) => field.type === "select")
          .title,
        placeholders: ["...", undefined, undefined],
        values: [[], undefined, undefined],
        selects: [undefined, undefined, undefined],
      },
    ]);
};
