import { Icon } from "@shared/ui";
import { Auth } from "@widgets/auth";

import styles from "./styles.module.scss";

export const SignInPage = () => {
  return (
    <div className={styles.sign_in}>
      <div className={styles.logo}>
        <Icon name="logo" />
      </div>
      <Auth />
    </div>
  );
};
