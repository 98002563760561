import { currentRoute } from "./model";
import { NotFoundPage } from "./ui";

export * as notFoundModel from "./model";
export { NotFoundPage } from "./ui";

export const NotFoundRoute = {
  view: NotFoundPage,
  route: currentRoute,
};
