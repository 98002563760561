import { selectDateModel } from "@features/filter/select-date";
import { findIndexes } from "@features/filter/select-date/lib/findIndexes";
import { DateOutputs } from "@features/filter/select-date/ui/date-outputs";
import { PopUp } from "@features/filter/select-date/ui/pop-up";
import { useOutsideClick } from "@shared/hooks/outside-click";
import { Icon } from "@shared/ui";
import { FC, useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";

export const SelectDate: FC<selectDateModel.SelectDateProps> = ({
  list,
  setSelect,
  type,
}) => {
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [currentDatePeriod, setCurrentDatePeriod] = useState<number>(0);
  const [firstDate, setFirstDate] = useState<string>("");
  const [lastDate, setLastDate] = useState<string>("");
  const [day, setDay] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [firstYear, setFirstYear] = useState<string>("");
  const [lastYear, setLastYear] = useState<string>("");
  const [firstMonth, setFirstMonth] = useState<string>("");
  const [lastMonth, setLastMonth] = useState<string>("");

  const ref = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(event!.target as Node)
    ) {
      setOpenDate(false);
    }
  });
  {
    list &&
      useEffect(() => {
        findIndexes(
          list,
          setSelect,
          type,
          currentDatePeriod,
          day,
          month,
          year,
          firstDate,
          lastDate,
        );
      }, [day, month, year, firstDate, lastDate, currentDatePeriod]);
  }
  return (
    <div className={styles.container}>
      <DateOutputs
        type={type}
        currentDatePeriod={currentDatePeriod}
        day={day}
        month={month}
        year={year}
        firstDate={firstDate}
        firstMonth={firstMonth}
        firstYear={firstYear}
        lastDate={lastDate}
        lastMonth={lastMonth}
        lastYear={lastYear}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        setFirstMonth={setFirstMonth}
        setFirstYear={setFirstYear}
        setFirstDate={setFirstDate}
        setLastDate={setLastDate}
        setLastYear={setLastYear}
        setLastMonth={setLastMonth}
      />

      <div
        ref={triggerRef}
        className={styles.open_trigger}
        onClick={() => {
          setOpenDate(!openDate);
        }}
      >
        <Icon name="triangle-down" />
      </div>

      {openDate && (
        <PopUp
          type={type}
          firstDate={firstDate}
          lastDate={lastDate}
          day={day}
          month={month}
          year={year}
          firstMonth={firstMonth}
          lastMonth={lastMonth}
          firstYear={firstYear}
          lastYear={lastYear}
          setDay={setDay}
          setMonth={setMonth}
          setYear={setYear}
          setFirstMonth={setFirstMonth}
          setFirstYear={setFirstYear}
          setFirstDate={setFirstDate}
          setLastDate={setLastDate}
          setLastYear={setLastYear}
          setLastMonth={setLastMonth}
          currentDatePeriod={currentDatePeriod}
          setCurrentDatePeriod={setCurrentDatePeriod}
          popupRef={ref}
        />
      )}
    </div>
  );
};
