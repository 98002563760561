import { currentRoute } from "./model";
import { FinancePage } from "./ui";

export * as financeModel from "./model";
export { FinancePage } from "./ui";

export const FinanceRoute = {
  view: FinancePage,
  route: currentRoute,
};
