import { selectDateModel } from "@features/filter/select-date";
import clsx from "clsx";
import React, { FC } from "react";

import styles from "./styles.module.scss";

export const PopUp: FC<selectDateModel.PopUpProps> = ({
  type,
  setCurrentDatePeriod,
  currentDatePeriod,
  setLastDate,
  setFirstDate,
  firstDate,
  lastDate,
  firstYear,
  lastYear,
  firstMonth,
  setFirstMonth,
  setLastYear,
  setLastMonth,
  setFirstYear,
  lastMonth,
  day,
  month,
  year,
  setDay,
  setYear,
  setMonth,
  popupRef,
}) => {
  const limits = {
    period: 2,
    date: 3,
    "period-large": 3,
  };

  const changePeriod = (currentDatePeriod: number) => {
    const limit = limits[type];
    if (currentDatePeriod < limit) {
      setCurrentDatePeriod(currentDatePeriod + 1);
    } else {
      setCurrentDatePeriod(0);
    }
  };

  const formatMonth = (inputMonth: string) => {
    let formattedMonth = inputMonth
      .replace(/[^0-9]/g, "")
      .replace(/(\d{2})(\d{0,4})/, (_, m, rest) => {
        const month = Math.min(parseInt(m, 10), 12).toString().padStart(2, "0");
        return rest.length > 0 ? `${month}.${rest}` : month;
      })
      .slice(0, 7);

    if (formattedMonth.length === 3 && inputMonth.length < month.length) {
      const lastChar = inputMonth.charAt(inputMonth.length - 1);
      if (lastChar !== ".") {
        formattedMonth = formattedMonth.slice(0, 2);
      }
    }
    return formattedMonth;
  };

  const formatYear = (value: string) => {
    return value.replace(/[^0-9]/g, "").replace(/^0+/, "");
  };

  return (
    <div
      className={clsx(styles["date-picker"], {
        [styles.default]:
          (currentDatePeriod === 3 && type === "date") ||
          (currentDatePeriod === 2 && type === "period"),
      })}
      ref={popupRef}
    >
      <div
        className={styles["date-picker__placeholder"]}
        onClick={() => changePeriod(currentDatePeriod)}
      >
        {type === "date" && selectDateModel.datesNames[currentDatePeriod]}
        {type === "period" && selectDateModel.periodNames[currentDatePeriod]}
        {type === "period-large" &&
          selectDateModel.periodLargeNames[currentDatePeriod]}
      </div>
      <div
        className={clsx(styles["date-picker__selectors"], {
          [styles.row]: type == "period-large",
        })}
      >
        {currentDatePeriod === 0 && type === "date" && (
          <input
            type="date"
            className={styles.input_data}
            onChange={(e) => setDay(e.target.value)}
            value={day}
            min="1000-01-01"
            max="3000-12-31"
          />
        )}

        {((currentDatePeriod === 1 && type === "date") ||
          (currentDatePeriod === 0 &&
            (type === "period" || type === "period-large"))) && (
          <input
            type="text"
            value={month}
            className={styles.input_data}
            onChange={(e) => setMonth(formatMonth(e.target.value))}
            placeholder="мм.гггг"
          />
        )}

        {((currentDatePeriod === 2 && type === "date") ||
          (currentDatePeriod === 1 &&
            (type === "period" || type === "period-large"))) && (
          <input
            type="year"
            className={styles.input_numbers}
            value={year}
            onChange={(e) => setYear(formatYear(e.target.value))}
            placeholder="гггг"
            maxLength={4}
          />
        )}

        {((currentDatePeriod === 3 && type === "date") ||
          (currentDatePeriod === 2 && type === "period")) && (
          <>
            <div className={styles["selector__item"]}>
              <input
                type="date"
                className={styles.input_data}
                onChange={(e) => setFirstDate(e.target.value)}
                value={firstDate}
                min="1000-01-01"
                max="3000-12-31"
              />
            </div>
            <div className={styles["selector__item"]}>
              <input
                type="date"
                className={styles.input_data}
                onChange={(e) => setLastDate(e.target.value)}
                value={lastDate}
                min="1000-01-01"
                max="3000-12-31"
              />
            </div>
          </>
        )}
        {currentDatePeriod === 2 && type == "period-large" && (
          <>
            <div className={styles["selector__item"]}>
              <input
                type="text"
                value={firstMonth}
                className={styles.input_data}
                onChange={(e) => setFirstMonth(formatMonth(e.target.value))}
                placeholder="мм.гггг"
              />
            </div>
            <span className={styles.divider} />
            <div className={styles["selector__item"]}>
              <input
                type="text"
                value={lastMonth}
                className={styles.input_data}
                onChange={(e) => setLastMonth(formatMonth(e.target.value))}
                placeholder="мм.гггг"
              />
            </div>
          </>
        )}
        {currentDatePeriod === 3 && type == "period-large" && (
          <>
            <div className={styles["selector__item"]}>
              <input
                type="year"
                className={styles.input_numbers}
                value={firstYear}
                onChange={(e) => setFirstYear(formatYear(e.target.value))}
                placeholder="гггг"
                maxLength={4}
              />
            </div>
            <span className={styles.divider} />
            <div className={styles["selector__item"]}>
              <input
                type="year"
                className={styles.input_numbers}
                value={lastYear}
                onChange={(e) => setLastYear(formatYear(e.target.value))}
                placeholder="гггг"
                maxLength={4}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
