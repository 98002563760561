import { entityModel } from "@entities/entity";
import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "input",
    title: "Название",
    value: "",
  },
  {
    type: "checkbox",
    title: "Активный",
    checked: false,
  },
  {
    type: "input",
    title: "Сортировка",
    value: "",
  },
  {
    type: "input",
    title: "ID",
    value: "",
  },
  {
    type: "icon",
  },
]).on(setFields, (_, field) => field);

export const $objectColumn = createStore<ColumnProps>({
  title: "Объект",
  renderFields: $fields.getState(),
  setFields: setFields,
  actionPlaceholder: "Выберите данные",
  onFilter: () => {},
  onSave: () => {},
  options: entityModel.$entities.getState(),
  context: {
    columnName: "Объект",
    deleteBlock: handleDeleteBlock,
  },
})
  .on($fields, (objectColumn, fields) => ({
    ...objectColumn,
    renderFields: fields,
  }))
  .on(entityModel.$entities, (objectColumn, entity) => ({
    ...objectColumn,
    options: entity,
  }));
