import { Nullable } from "@shared/types";
import { createEvent, createStore } from "effector";

export type ActiveTabProps = {
  [key: string]: Nullable<{ name: string; id?: string | number }>;
};

export const activeTabChanged = createEvent<ActiveTabProps>();
export const $activeTab = createStore<ActiveTabProps>({});
$activeTab.on(activeTabChanged, (_, tab) => tab);

export const $multiSelect = createStore<number[]>([]);
export const addMultiSelect = createEvent<number>();
$multiSelect.on(addMultiSelect, (ids, newId) =>
  ids.includes(newId)
    ? ids.filter((id) => id !== newId).sort((a, b) => a - b)
    : [...ids, newId].sort((a, b) => a - b),
);
