import { sessionModel } from "@entities/session";
import { routes } from "@shared/config";
import {
  chainRoute,
  redirect,
  RouteInstance,
  RouteParams,
  RouteParamsAndQuery,
} from "atomic-router";
import { createEvent, sample } from "effector";

export const chainAuthorized = <Params extends RouteParams>(
  route: RouteInstance<Params>,
) => {
  const checkSessionStarted = createEvent<RouteParamsAndQuery<Params>>();

  const alreadyAuthorized = sample({
    clock: checkSessionStarted,
    filter: sessionModel.$isAuth,
  });

  const forbidden = sample({
    clock: checkSessionStarted,
    filter: sessionModel.$isAuth.map((v) => !v),
  });

  redirect({
    clock: forbidden,
    route: routes.auth.signIn,
  });

  return chainRoute({
    route,
    beforeOpen: checkSessionStarted,
    openOn: [alreadyAuthorized],
    cancelOn: [forbidden],
  });
};
