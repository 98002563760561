import { Search } from "@features/filter/search";
import { useOutsideClick } from "@shared/hooks/outside-click";
import { formatPercentageInput } from "@shared/lib/formatInput";
import { Nullable } from "@shared/types";
import { Icon } from "@shared/ui";
import { FC, useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";

export type NumericalProps = {
  list?: Nullable<any[]>;
  setSelect?: (indexes: number[] | any) => any;
};

export const NumericalSearch: FC<NumericalProps> = ({ list, setSelect }) => {
  const [value, setValue] = useState<string>("");
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const searchField = useRef(null);
  useOutsideClick(searchField, setFilterOpen as any);

  const findMatchingIndices = () => {
    if (!list || !list.length) {
      return [];
    }
    const matchingIndices: number[] = [];
    {
      value.length > 0 &&
        list.forEach((item, index) => {
          if (
            item.replace(/\s/g, "").replace(",", ".") ===
            value.replace(/\s/g, "").replace(",", ".")
          ) {
            matchingIndices.push(index);
          }
        });
    }
    return matchingIndices;
  };

  useEffect(() => {
    setSelect!(findMatchingIndices());
  }, [value]);

  return (
    <div className={styles.container} ref={searchField}>
      <div className={styles.text}>{value}</div>
      <div
        className={styles.icon}
        onClick={() => {
          setFilterOpen((prev) => !prev);
        }}
      >
        <Icon name="magnifier" />
      </div>

      {filterOpen && (
        <div className={styles.filter_container}>
          <Search
            value={value}
            placeholder="..."
            onValueChange={(e) =>
              setValue(formatPercentageInput(e.currentTarget.value))
            }
          />
        </div>
      )}
    </div>
  );
};
