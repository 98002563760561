import { SelectSearch } from "@features/filter/select-search";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Field, Nullable } from "@shared/types";
import { Icon } from "@shared/ui";
import clsx from "clsx";
import { FC, useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";

type ActionProps = {
  fields: Field[];
  isRollup: boolean;
  placeholder?: string;
  list?: Nullable<any[]>;
  disable?: boolean;
  hide?: boolean;
  actionStatic?: {
    icon?: string;
    title?: string;
    text?: string;
  };
  setFields: (fields: Field[]) => void;
  onSelect?: () => void;
};

type TSelectedItem = {
  id: number | undefined;
  name: string;
};

export const Action: FC<ActionProps> = ({
  fields,
  isRollup,
  list,
  placeholder,
  disable,
  hide,
  actionStatic,
  setFields,
  onSelect,
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<TSelectedItem>();
  const ref = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setOpen(false);
    if (selected && list) {
      const selectedItem = list.find((item) => item.ID === selected.id);
      if (selectedItem) {
        const updatedFields = fields.map((field) => {
          switch (field.title) {
            case "ID":
              return { ...field, value: selectedItem.ID };
            case "Название":
              return { ...field, value: selectedItem.Name };
            case "Активный":
              return { ...field, checked: selectedItem.Active };
            case "Сортировка":
              return { ...field, value: selectedItem.SortNumber };
            default:
              return field;
          }
        });
        setFields(updatedFields);
      }
    }
  }, [selected]);

  return (
    <DropdownMenu.Root onOpenChange={setOpen} open={isOpen}>
      {!hide && (
        <DropdownMenu.Trigger
          disabled={disable || isRollup}
          className={clsx(styles.action, {
            [styles.active]: isOpen,
            [styles.hide]: isRollup,
          })}
        >
          {!isRollup && (
            <>
              {!actionStatic && (
                <>
                  <span className={styles.text}>
                    {selected?.name || placeholder}
                  </span>

                  <div
                    ref={ref}
                    className={clsx(styles.icon, { [styles.active]: isOpen })}
                  >
                    {!disable && <Icon name="triangle-down" />}
                  </div>
                </>
              )}

              {actionStatic && (
                <>
                  <div className={styles.static}>
                    <span className={styles.static_icon}>
                      <Icon name={actionStatic.icon} />
                    </span>

                    <span className={styles.static_text}>
                      {actionStatic.title}
                    </span>
                  </div>

                  <span className={styles.static_text}>
                    {actionStatic.text}
                  </span>
                </>
              )}
            </>
          )}
        </DropdownMenu.Trigger>
      )}

      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.content}>
          <SelectSearch
            popupRef={triggerRef}
            list={list}
            setSelected={(selected: TSelectedItem) => {
              setSelected(selected);
              if (onSelect) {
                onSelect();
              }
            }}
          />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
