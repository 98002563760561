import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "input",
    title: "Название",
    value: "",
  },
  {
    type: "checkbox",
    title: "Активный",
    checked: false,
  },
  {
    type: "input",
    title: "Сортировка",
    value: "",
  },
  {
    type: "icon",
  },
  {
    type: "select",
    placeholders: ["...", undefined, undefined],
    title: "В группе",
    values: [[], undefined, undefined],
    selects: [0, undefined, undefined],
  },
  {
    type: "select",
    placeholders: ["...", undefined, undefined],
    title: "В группе",
    values: [[], undefined, undefined],
    selects: [0, undefined, undefined],
  },
]).on(setFields, (_, field) => field);

export const $groupCalculation = createStore<ColumnProps>({
  title: "Калькуляция группы",
  renderFields: $fields.getState(),
  setFields: setFields,
  actionPlaceholder: "Выберите данные",
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "Калькуляция группы",
    deleteBlock: handleDeleteBlock,
  },
}).on($fields, (groupCalculation, fields) => ({
  ...groupCalculation,
  renderFields: fields,
}));
