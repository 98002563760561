import { chainAuthorized } from "@shared/lib/route";

import { currentRoute } from "./model";
import { SettingsPage } from "./ui";

export * as settingsModel from "./model";
export { SettingsPage } from "./ui";

export const SettingsRoute = {
  view: SettingsPage,
  route: chainAuthorized(currentRoute),
};
