import { departmentsGetFx } from "@shared/api";
import { createEffect, restore } from "effector";

export type Department = {
  ID: number;
  Name: string;
  Active: boolean;
  SortNumber: number;
};

export const departmentsGet = createEffect(departmentsGetFx);

export const $departments = restore<Department[]>(departmentsGet, []);
