import { sectionModel } from "@entities/section";
import { entitiesGetFx } from "@shared/api";
import { createEffect, restore, sample } from "effector";

export type Entity = {
  ID: number;
  Name: string;
  Active: boolean;
  SortNumber: number;
  Icon: string;
};

export const entitiesGet = createEffect(entitiesGetFx);

export const $entities = restore<Entity[]>(entitiesGet, []);

sample({
  clock: entitiesGetFx.doneData,
  source: entitiesGetFx.doneData,
  fn: (result: any) =>
    sectionModel.$sections?.map((item) =>
      item
        ?.find((name) => name.Name == "objects")
        ?.Sections.push(result.map((item: Entity) => item as Entity)),
    ),
});
