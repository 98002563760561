import { BASE_URL } from "@shared/config";
import WebSocketController from "@shared/lib/ws";
import { ComponentType } from "react";

export const wsController = new WebSocketController({
  responseTimeoutMs: 10000,
  connectionTimeout: 5000,
  maxRetries: Infinity,
});

wsController.connect(BASE_URL);

export const withWebsocket =
  <T extends object>(Component: ComponentType<T>) =>
  (other: T) => {
    return <Component wsController={wsController} {...other} />;
  };
