import { Nullable } from "@shared/types";

export const periodNames = {
  0: "Месяц",
  1: "Год",
  2: "Период",
} as { [key: number]: string };

export const datesNames = {
  0: "День",
  1: "Месяц",
  2: "Год",
  3: "Период",
} as { [key: number]: string };

export const periodLargeNames = {
  0: "Месяц",
  1: "Год",
  2: "Период месяцы",
  3: "Период годы",
} as { [key: number]: string };

export type PopUpProps = {
  type: "date" | "period" | "period-large";
  popupRef: React.RefObject<HTMLDivElement>;
  currentDatePeriod: number;
  setCurrentDatePeriod: (value: number) => void;
  firstDate: string;
  setFirstDate: (value: string) => void;
  lastDate: string;
  setLastDate: (value: string) => void;
  day: string;
  setDay: (value: string) => void;
  month: string;
  setMonth: (value: string) => void;
  year: string;
  setYear: (value: string) => void;
  firstYear: string;
  setFirstYear: (value: string) => void;
  lastYear: string;
  setLastYear: (value: string) => void;
  firstMonth: string;
  setFirstMonth: (value: string) => void;
  lastMonth: string;
  setLastMonth: (value: string) => void;
};

export type SelectDateProps = {
  list?: Nullable<any[]>;
  setSelect?: (indexes: number[]) => void;
  type: "date" | "period" | "period-large";
};

export type DateFieldsProps = {
  type: "date" | "period" | "period-large";
  currentDatePeriod: number;
  firstDate: string;
  setFirstDate: (value: string) => void;
  lastDate: string;
  setLastDate: (value: string) => void;
  day: string;
  setDay: (value: string) => void;
  month: string;
  setMonth: (value: string) => void;
  year: string;
  setYear: (value: string) => void;
  firstYear: string;
  setFirstYear: (value: string) => void;
  lastYear: string;
  setLastYear: (value: string) => void;
  firstMonth: string;
  setFirstMonth: (value: string) => void;
  lastMonth: string;
  setLastMonth: (value: string) => void;
};
