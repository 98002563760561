import { FinanceRoute } from "@pages/finance";
import { HomeRoute } from "@pages/home";
import { NotFoundRoute } from "@pages/not-found";
import { SettingsRoute } from "@pages/settings";
import { SignInRoute } from "@pages/sign-in";
import { createRoutesView } from "atomic-router-react";

export const Pages = createRoutesView({
  routes: [SignInRoute, HomeRoute, SettingsRoute, NotFoundRoute, FinanceRoute],
});
