import { authFx } from "@shared/api";
import { createEvent, createStore } from "effector";
import { every, or } from "patronum";

export const loginChanged = createEvent<string>();
export const passwordChanged = createEvent<string>();
export const formSubmitted = createEvent();

export const $login = createStore("admin");
export const $loginError = createStore<null | "empty" | "invalid">(null);

export const $password = createStore("admin");
export const $passwordError = createStore<null | "empty" | "invalid">(null);

export const $error = createStore<Error | null>(null);
export const $passwordLoginPending = authFx.pending;

export const $formDisabled = or($passwordLoginPending);

export const $formValid = every({
  stores: [$loginError, $passwordError],
  predicate: null,
});

$login.on(loginChanged, (_, login) => login);
$password.on(passwordChanged, (_, password) => password);
$error.reset(formSubmitted);
