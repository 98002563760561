import { appStarted } from "@shared/config";
import JsSHA from "jssha";
import ReconnectingWebSocket from "reconnecting-websocket";
import { v4 as uuid } from "uuid";

export interface ControllerOptions {
  Controller: string;
  Action: string;
}

interface WebSocketOptions {
  responseTimeoutMs?: number;
  connectionTimeout?: number;
  maxRetries?: number;
}

interface MessageOptions {
  Controller: string;
  Action: string;
  Serial?: string;
  Hash?: string;
  Error?: string;
  Data?: any[];
}

interface Handler {
  Controller: string;
  Action: string;
  Hash: string;
  Data: any[];
}

class WebSocketController {
  private socket!: ReconnectingWebSocket;
  private messageHandlers: Map<string, (data: any) => void> = new Map();
  private actionHandlers: Map<string, (handler: Handler) => any> = new Map();
  private readonly responseTimeoutMs: number;
  private readonly connectionTimeout: number;
  private readonly maxRetries: number;

  constructor({
    responseTimeoutMs = 5000,
    connectionTimeout = 1000,
    maxRetries = 3,
  }: WebSocketOptions = {}) {
    this.responseTimeoutMs = responseTimeoutMs;
    this.connectionTimeout = connectionTimeout;
    this.maxRetries = maxRetries;
  }

  public connect(url: string): void {
    const options = {
      connectionTimeout: this.connectionTimeout,
      maxRetries: this.maxRetries,
    };
    this.socket = new ReconnectingWebSocket(url, [], options);

    this.socket.addEventListener("open", () => {
      appStarted();
    });

    this.socket.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      this.messageHandlers.forEach((handler) => handler(data));
      this.actionHandlers.forEach((handler) => {
        const result = handler(data);
        if (result) {
          console.log("[DEBUG]", "actionHandlers RESULT:", result);
        }
      });
    });

    this.socket.addEventListener("error", (event) => {
      console.error("WebSocket error:", event);
    });

    this.socket.addEventListener("close", (event) => {
      console.log("WebSocket connection closed:", event);
    });
  }

  getSHA1Hash(data: string) {
    const shaObj = new JsSHA("SHA-1", "TEXT");
    shaObj.update(data);
    return shaObj.getHash("HEX").toUpperCase();
  }

  checkData(data: any, hash: string) {
    const current = this.getSHA1Hash(JSON.stringify(data));
    return current === hash;
  }

  async send(opt: MessageOptions): Promise<any> {
    opt.Serial = uuid();
    if (!opt.Data) {
      opt.Data = [];
    }
    opt.Hash = this.getSHA1Hash(JSON.stringify(opt.Data));
    return new Promise((resolve, reject) => {
      if (this.socket.readyState === WebSocket.OPEN) {
        const message: string = JSON.stringify(opt);
        this.socket.send(message);
        const responseHandler = (response: any) => {
          if (response.Serial === opt.Serial) {
            const isDataCorrect = this.checkData(response.Data, response.Hash);
            if (response.Error !== "") {
              reject(new Error(response.Error));
              clearTimeout(timeout);
              this.removeMessageHandler(handlerId);
            }
            if (!isDataCorrect) {
              reject(new Error("Bad Data!"));
              clearTimeout(timeout);
              this.removeMessageHandler(handlerId);
            }

            clearTimeout(timeout);
            this.removeMessageHandler(handlerId);
            resolve(response.Data);
          }
        };
        const handlerId = this.addMessageHandler(responseHandler);
        const timeout = setTimeout(() => {
          this.removeMessageHandler(handlerId);
          reject(
            new Error(
              `Timeout: No response received within ${this.responseTimeoutMs}ms`,
            ),
          );
        }, this.responseTimeoutMs);
      } else {
        console.error("WebSocket is not open. Message not sent:", opt);
        reject(new Error("WebSocket is not open"));
      }
    });
  }

  public addMessageHandler(handler: (data: any) => void): string {
    const handlerId = uuid();
    this.messageHandlers.set(handlerId, handler);
    return handlerId;
  }

  public removeMessageHandler(handlerId: string): void {
    this.messageHandlers.delete(handlerId);
  }

  public addActionHandler(
    handler: ControllerOptions,
    callback: (data: any) => any,
  ): string {
    const handlerId = uuid();
    this.actionHandlers.set(handlerId, (data: any) => {
      if (
        data.Controller === handler.Controller &&
        data.Action === handler.Action
      ) {
        return callback(data);
      }
    });
    return handlerId;
  }

  public removeActionHandler(handlerId: string): void {
    this.actionHandlers.delete(handlerId);
  }

  public setBinaryMode(mode: "blob" | "arraybuffer"): void {
    if (this.socket) {
      this.socket.binaryType = mode;
    }
  }

  public sendFile(file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target && event.target.result) {
          const fileContent = event.target.result;

          if (this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(fileContent);
            resolve();
          } else {
            reject(new Error("WebSocket is not open"));
          }
        } else {
          reject(new Error("Error reading the file"));
        }
      };

      reader.onerror = (error) => {
        reject(new Error("Error reading the file: " + error));
      };

      reader.readAsArrayBuffer(file);
    });
  }
}

export default WebSocketController;
