import { sectionModel } from "@entities/section";
import { employeesGetFx } from "@shared/api";
import { createEffect, restore, sample } from "effector";

export type Employee = {
  ID: number;
  DepartmentID: number;
  ManualID: number;
  LastName: string;
  FirstName: string;
  PatronymicName: string;
  Active: boolean;
  SortNumber: number;
  Position: string;
  PhoneNumber: string;
  Telegram: string;
  Email: string;
  Login: string;
  Password: string;
};

export const employeesGet = createEffect(employeesGetFx);

export const $employees = restore<Employee[]>(employeesGet, []);

sample({
  clock: employeesGet.doneData,
  source: employeesGet.doneData,
  fn: (result: any) =>
    sectionModel.$sections?.map((item) =>
      item
        ?.find((name) => name.Name == "employee")
        ?.Sections.push(result.map((item: Employee) => item as Employee)),
    ),
});
