import { ChangeEventHandler, FC, HTMLAttributes } from "react";

import styles from "./styles.module.scss";

type SearchProps = HTMLAttributes<HTMLInputElement> & {
  value: string;
  onValueChange: ChangeEventHandler<HTMLInputElement>;
  textAlign?: "center" | "left" | "right";
  placeholder?: string;
};

export const Search: FC<SearchProps> = ({
  value,
  onValueChange,
  placeholder,
  textAlign,
}) => {
  return (
    <input
      type="text"
      style={{ textAlign: textAlign }}
      className={styles.search}
      placeholder={placeholder}
      onChange={onValueChange}
      value={value}
    />
  );
};
