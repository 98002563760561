import { Context as ContextProps } from "@shared/types";
import { Icon } from "@shared/ui";
import { ContextItem } from "@shared/ui/context-item";
import { FC } from "react";

export const Edit: FC<ContextProps> = ({ editingIndex, setEditingIndex }) => {
  return (
    <ContextItem
      onClick={() => {
        if (editingIndex) {
          setEditingIndex(editingIndex);
        }
      }}
    >
      <Icon name="edit" /> Редактировать
    </ContextItem>
  );
};
