import { Nullable } from "@shared/types";

export const findIndexes = (
  list?: Nullable<string[]>,
  setSelect?: (indexes: number[]) => void,
  type?: "date" | "period" | "period-large",
  currentDatePeriod?: number,
  day?: string,
  month?: string,
  year?: string,
  firstDate?: string,
  lastDate?: string,
) => {
  setSelect!([]);

  if (!list || !setSelect || currentDatePeriod === undefined) {
    return;
  }

  const conditions: ((date: string) => boolean)[] = [];

  if (type === "date") {
    conditions.push(
      (date: string) => date === day,
      (date: string) => {
        const [dateYear, dateMonth] = date.split("-");
        const formattedMonth = `${dateMonth}.${dateYear}`;
        return formattedMonth === month;
      },
      (date: string) => date.startsWith(year!),
      (date: string) => date >= firstDate! && date <= lastDate!,
    );
  }
  if (type === "period") {
    conditions.push(
      (date: string) => {
        const [dateYear, dateMonth] = date.split(".");
        const formattedMonth = `${dateYear}.${dateMonth}`;
        return formattedMonth === month;
      },
      (date: string) => date.startsWith(year!),
      (date: string) => date >= firstDate! && date <= lastDate!,
    );
  }

  if (
    (type === "date" &&
      ((currentDatePeriod === 0 && !day) ||
        (currentDatePeriod === 1 && !month) ||
        (currentDatePeriod === 2 && !year) ||
        (currentDatePeriod === 3 && (!firstDate || !lastDate)))) ||
    ((type === "period" || type === "period-large") &&
      ((currentDatePeriod === 0 && !month) ||
        (currentDatePeriod === 1 && !year) ||
        (currentDatePeriod === 2 && (!firstDate || !lastDate))))
  ) {
    return;
  }
  const indexes: number[] = list
    .map((date, index) => ({ date, index }))
    .filter(({ date }) => conditions[currentDatePeriod](date))
    .map(({ index }) => index);

  if (indexes.length === 0) {
    setSelect([-1]);
  } else {
    setSelect(indexes);
  }
};
