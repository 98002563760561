import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "select",
    placeholders: ["...", "...", "..."],
    values: [[], [], []],
    selects: [0, undefined, undefined],
  },
  {
    type: "select",
    placeholders: ["...", "...", "..."],
    values: [[], [], []],
    selects: [0, undefined, undefined],
  },
  {
    type: "select",
    placeholders: ["...", "...", "..."],
    values: [[], [], []],
    selects: [0, undefined, undefined],
  },
  {
    type: "select",
    placeholders: ["...", "...", "..."],
    values: [[], [], []],
    selects: [0, undefined, undefined],
  },
]).on(setFields, (_, field) => field);

export const $incomeColumn = createStore<ColumnProps>({
  title: "Доход A1",
  renderFields: $fields.getState(),
  setFields: setFields,
  actionPlaceholder: "Выберите данные",
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "Доход A1",
    deleteBlock: handleDeleteBlock,
  },
}).on($fields, (incomeColumn, fields) => ({
  ...incomeColumn,
  renderFields: fields,
}));
