import { Context as ContextProps } from "@shared/types";
import { ContextItem, Icon } from "@shared/ui";
import { FC } from "react";

export const Copy: FC<ContextProps> = () => {
  return (
    <ContextItem onClick={() => {}}>
      <Icon name="copy" /> Копировать
    </ContextItem>
  );
};
