import { $accrualsColumn } from "@pages/settings/ui/entity/model/accruals.column";
import { $commissionColumn } from "@pages/settings/ui/entity/model/commission.column";
import { $incomeColumn } from "@pages/settings/ui/entity/model/income.column";
import { $objectIncomeColumn } from "@pages/settings/ui/entity/model/object-income.column";
import { $reportColumn } from "@pages/settings/ui/entity/model/report.column";
import { $typesPayrollsColumn } from "@pages/settings/ui/entity/model/types-payrolls.column";
import { $viewColumn } from "@pages/settings/ui/entity/model/view.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine(
  [
    $accrualsColumn,
    $commissionColumn,
    $objectIncomeColumn,
    $incomeColumn,
    $viewColumn,
    $reportColumn,
    $typesPayrollsColumn,
  ],
  ([
    accrualsColumnValue,
    commissionColumnValue,
    objectIncomeColumn,
    incomeColumnValue,
    viewColumn,
    reportColumn,
    typesPayrollsColumn,
  ]) => [
    accrualsColumnValue,
    commissionColumnValue,
    objectIncomeColumn,
    incomeColumnValue,
    viewColumn,
    reportColumn,
    typesPayrollsColumn,
  ],
).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
