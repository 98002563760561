import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const indexChanged = createEvent<number>();
export const $indexes = createStore<number[]>([]).on(
  indexChanged,
  (indexes, newIndex) =>
    indexes.includes(newIndex)
      ? indexes.filter((index) => index !== newIndex)
      : [...indexes, newIndex],
);

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "input",
    value: "8",
  },
  {
    type: "input",
    value: "35",
  },
  {
    type: "input",
    value: "3",
  },
  {
    type: "input",
    value: "1",
  },
  {
    type: "input",
    value: "1",
  },
]).on(setFields, (_, field) => field);

export const $employeeColumn = createStore<ColumnProps>({
  title: "Сотрудник",
  width: 88,
  fieldHeight: 72,
  renderFields: $fields.getState(),
  setFields: setFields,
  actionHide: true,
  colorAlternation: true,
  solidMod: true,
  isAlwaysOpen: true,
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "Сотрудник",
    deleteBlock: handleDeleteBlock,
  },
  headerFilter: {
    type: "select",
    list: $fields.getState().map((cell) => cell.value),
    selects: $indexes.getState(),
    setSelect: indexChanged,
  },
})
  .on($fields, (employeeColumn, fields) => ({
    ...employeeColumn,
    renderFields: fields,
    headerFilter: {
      ...employeeColumn.headerFilter,
      list: fields.map((cell) => cell.value),
    },
  }))
  .on($indexes, (employeeColumn, indexes) => ({
    ...employeeColumn,
    headerFilter: {
      ...employeeColumn.headerFilter,
      selects: indexes,
    },
  }));
