import { sectionModel } from "@entities/section";
import { sessionModel } from "@entities/session";
import { authFx } from "@shared/api";
import { routes } from "@shared/config";
import { authModel } from "@widgets/auth";
import { redirect } from "atomic-router";
import { createEffect, createEvent, sample } from "effector";
import { and, not, reset } from "patronum";

export const pageMounted = createEvent();

export const currentRoute = routes.auth.signIn;

export const signInFx = createEffect(authFx);

sample({
  clock: signInFx.doneData,
  source: sessionModel.$token,
  fn(_, response) {
    return response[0].Token;
  },
  target: sessionModel.tokenReceived,
});

redirect({
  clock: signInFx.doneData,
  route: routes.private.home,
});

sample({
  clock: signInFx.failData,
  fn: () => alert("Неверные данные для входа"),
});

sample({
  clock: authModel.formSubmitted,
  source: { Login: authModel.$login, Password: authModel.$password },
  filter: and(not(authModel.$formDisabled), authModel.$formValid),
  target: signInFx,
});

sample({
  clock: signInFx.doneData,
  filter: sessionModel.$isAuth,
  target: sessionModel.userGet,
});

sample({
  clock: signInFx.doneData,
  filter: sessionModel.$isAuth,
  target: sectionModel.sectionsGet,
});

reset({
  clock: pageMounted,
  target: [
    authModel.$login,
    authModel.$loginError,
    authModel.$password,
    authModel.$passwordError,
    authModel.$error,
  ],
});
