import { SubMenuProps } from "@shared/types";
import { createEvent, createStore } from "effector";

export type Pages =
  | "reports"
  | "payment-debit"
  | "payment-credit"
  | "wage"
  | "calculation"
  | "counter"
  | "settings";

export const $pagesSubMenu = createStore<SubMenuProps>({
  finance_sub: {
    counter: [
      { icon: "counter-employees", label: "Сводная сотрудники" },
      { icon: "counter", label: "Альперов" },
      { icon: "counter", label: "Продакш" },
    ],
    wage: [
      { icon: "counter-employees", label: "Детализованная" },
      { icon: "sections", label: "Сводная" },
    ],
    calculation: [
      { icon: "counter-employees", label: "Детализованная" },
      { icon: "sections", label: "Сводная" },
    ],
  },
});

export const activePageChanged = createEvent<Pages>();
export const $activePage = createStore<Pages | null>(null);

export const activeTabChanged = createEvent<string>();
export const $activeTab = createStore<string>(
  window.location.toString().split("/")[3] + "_sub",
);

export const setActiveSub = createEvent<boolean>();
export const $activeSub = createStore<boolean>(false);

export const setSelectSub = createEvent<number>();
export const $selectSub = createStore<number>(0);

$activePage.on(activePageChanged, (_, page) => page);
$activeTab.on(activeTabChanged, (_, tab) => tab);
$activeSub.on(setActiveSub, (_, active) => active);
$selectSub.on(setSelectSub, (_, select) => select);
$activePage.watch(() => {
  setSelectSub(0);
});
