import { createHistoryRouter, createRoute } from "atomic-router";
import { sample } from "effector";
import { createBrowserHistory } from "history";

import { appStarted } from "./init";

export const routes = {
  auth: {
    signIn: createRoute(),
  },
  private: {
    home: createRoute(),
    settings: createRoute(),
    finance: createRoute<{ catalog: string }>(),
    music: createRoute<{ catalog: string }>(),
    managers: createRoute<{ catalog: string }>(),
  },
  errors: {
    notFound: createRoute(),
  },
};

export const notFoundRoute = routes.errors.notFound;

const mappedRoutes = [
  { path: "/", route: routes.private.home },
  { path: "/sign-in", route: routes.auth.signIn },
  { path: "/settings", route: routes.private.settings },
  { path: "/finance/:catalog+", route: routes.private.finance },
  { path: "/music/:catalog+", route: routes.private.music },
  { path: "/managers/:catalog+", route: routes.private.managers },
  { path: "/404", route: routes.errors.notFound },
];

export const router = createHistoryRouter({
  routes: mappedRoutes,
  notFoundRoute,
});

sample({
  clock: appStarted,
  fn: () => createBrowserHistory(),
  target: router.setHistory,
});
