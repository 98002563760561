import { entityModel } from "@entities/entity";
import { sectionModel } from "@entities/section";
import { routes } from "@shared/config";
import { chainRoute } from "atomic-router";

export const currentRoute = routes.private.finance;

chainRoute({
  route: routes.private.finance,
  beforeOpen: sectionModel.sectionsWithEmployeeGet,
});

chainRoute({
  route: routes.private.finance,
  beforeOpen: entityModel.entitiesGet,
});
