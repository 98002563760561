import { router } from "@shared/config";
import { RouterProvider } from "atomic-router-react";
import { ComponentType } from "react";

export const withRouter =
  <T extends object>(Component: ComponentType<T>) =>
  (other: T) => (
    <RouterProvider router={router}>
      <Component {...other} />
    </RouterProvider>
  );
