import { $appointmentsColumn } from "@pages/finance/ui/counter2/model/appointments.column";
import { $customerColumn } from "@pages/finance/ui/counter2/model/customer.column";
import { $dateColumn } from "@pages/finance/ui/counter2/model/date.column";
import { $memberColumn } from "@pages/finance/ui/counter2/model/member.column";
import { $objectColumn } from "@pages/finance/ui/counter2/model/object.column";
import { $orderColumn } from "@pages/finance/ui/counter2/model/order.column";
import { $performerColumn } from "@pages/finance/ui/counter2/model/performer.column";
import { $periodColumn } from "@pages/finance/ui/counter2/model/period.column";
import { $roleColumn } from "@pages/finance/ui/counter2/model/role.column";
import { $sumColumn } from "@pages/finance/ui/counter2/model/sum.column";
import { $technicalDepartmentColumn } from "@pages/finance/ui/counter2/model/technical-department.column";
import { $tmColumn } from "@pages/finance/ui/counter2/model/tm.column";
import { $tpColumn } from "@pages/finance/ui/counter2/model/tp.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine(
  [
    $technicalDepartmentColumn,
    $periodColumn,
    $dateColumn,
    $sumColumn,
    $customerColumn,
    $tmColumn,
    $appointmentsColumn,
    $orderColumn,
    $objectColumn,
    $tpColumn,
    $memberColumn,
    $performerColumn,
    $roleColumn,
  ],
  ([
    technicalDepartmentColumn,
    periodColumn,
    dateColumn,
    sumColumn,
    customerColumn,
    tmColumn,
    appointmentsColumn,
    orderColumn,
    objectColumn,
    tpColumn,
    memberColumn,
    performerColumn,
    roleColumn,
  ]) => [
    technicalDepartmentColumn,
    periodColumn,
    dateColumn,
    sumColumn,
    customerColumn,
    tmColumn,
    appointmentsColumn,
    orderColumn,
    objectColumn,
    tpColumn,
    memberColumn,
    performerColumn,
    roleColumn,
  ],
).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
