import { selectDateModel } from "@features/filter/select-date";
import { Icon } from "@shared/ui";
import clsx from "clsx";
import React, { FC } from "react";

import styles from "./styles.module.scss";

export const DateOutputs: FC<selectDateModel.DateFieldsProps> = (props) => {
  const {
    type,
    currentDatePeriod,
    day,
    month,
    year,
    firstDate,
    lastDate,
    firstMonth,
    firstYear,
    lastYear,
    lastMonth,
  } = props;

  const updateDate = (increment: boolean) => {
    if (type === "date") {
      if (currentDatePeriod === 0) {
        const date = new Date(day);
        date.setDate(date.getDate() + (increment ? 1 : -1));
        props.setDay(date.toISOString().split("T")[0]);
      } else if (currentDatePeriod === 1) {
        const [mm, yyyy] = month.split(".");
        const date = new Date(`${yyyy}-${mm}-01`);
        date.setMonth(date.getMonth() + (increment ? 1 : -1));
        props.setMonth(
          `${String(date.getMonth() + 1).padStart(2, "0")}.${date.getFullYear()}`,
        );
      } else if (currentDatePeriod === 2) {
        const newYear = parseInt(year) + (increment ? 1 : -1);
        props.setYear(String(newYear));
      }
    } else if (type === "period" || type === "period-large") {
      if (currentDatePeriod === 0) {
        const [mm, yyyy] = month.split(".");
        const date = new Date(`${yyyy}-${mm}-01`);
        date.setMonth(date.getMonth() + (increment ? 1 : -1));
        props.setMonth(
          `${String(date.getMonth() + 1).padStart(2, "0")}.${date.getFullYear()}`,
        );
      } else if (currentDatePeriod === 1) {
        const newYear = parseInt(year) + (increment ? 1 : -1);
        props.setYear(String(newYear));
      }
    }
  };

  const formatDate = (date: string) => date.replace(/-/g, ".");

  const isDateFilled = () => {
    if (type === "date") {
      if (currentDatePeriod === 0) return !!day;
      if (currentDatePeriod === 1) return !!month;
      if (currentDatePeriod === 2) return !!year;
    } else if (type === "period" || type === "period-large") {
      if (currentDatePeriod === 0) return !!month;
      if (currentDatePeriod === 1) return !!year;
    }
    return false;
  };

  const getDisplayDate = () => {
    if (type === "date") {
      if (currentDatePeriod === 0 && day)
        return <span className={styles.text}>{formatDate(day)}</span>;
      if (currentDatePeriod === 1 && month)
        return <span className={styles.text}>{month}</span>;
      if (currentDatePeriod === 2 && year)
        return <span className={styles.text}>{year}</span>;
      if (currentDatePeriod === 3 && firstDate && lastDate) {
        return (
          <>
            <span className={styles.text}>{formatDate(firstDate)}</span>
            <span className={styles.text}>{formatDate(lastDate)}</span>
          </>
        );
      }
    }
    if (type === "period") {
      if (currentDatePeriod === 0 && month)
        return <span className={styles.text}>{month}</span>;
      if (currentDatePeriod === 1 && year)
        return <span className={styles.text}>{year}</span>;
      if (currentDatePeriod === 2 && firstDate && lastDate) {
        return (
          <>
            <span className={styles.text}>{formatDate(firstDate)}</span>
            <span className={styles.text}>{formatDate(lastDate)}</span>
          </>
        );
      }
    }
    if (type === "period-large") {
      if (currentDatePeriod === 0 && month)
        return <span className={styles.text}>{month}</span>;
      if (currentDatePeriod === 1 && year)
        return <span className={styles.text}>{year}</span>;
      if (currentDatePeriod === 2 && firstMonth && lastMonth) {
        return (
          <>
            <span className={styles.text}>{formatDate(firstMonth)}</span>
            <span className={styles.text}>{formatDate(lastMonth)}</span>
          </>
        );
      }
      if (currentDatePeriod === 3 && firstYear && lastYear) {
        return (
          <>
            <span className={styles.text}>{formatDate(firstYear)}</span>
            <span className={styles.text}>{formatDate(lastYear)}</span>
          </>
        );
      }
    }
    return <span className={styles.text}>-</span>;
  };

  return (
    <div
      className={clsx(styles.date, {
        [styles.period]:
          (type === "date" &&
            currentDatePeriod === 3 &&
            firstDate &&
            lastDate) ||
          (currentDatePeriod === 2 &&
            firstDate &&
            lastDate &&
            type === "period") ||
          (currentDatePeriod === 2 &&
            firstMonth &&
            lastMonth &&
            type === "period-large") ||
          (currentDatePeriod === 3 &&
            firstYear &&
            lastYear &&
            type === "period-large"),
      })}
    >
      {isDateFilled() && (
        <div className={styles.arrow} onClick={() => updateDate(false)}>
          <Icon name="arrow-left" />
        </div>
      )}
      <div className={styles.date_value}>{getDisplayDate()}</div>
      {isDateFilled() && (
        <div className={styles.arrow} onClick={() => updateDate(true)}>
          <Icon name="arrow-right" />
        </div>
      )}
    </div>
  );
};
