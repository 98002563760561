import { $accrualsColumn } from "@pages/settings/ui/employees/model/accruals.column";
import { $commissionColumn } from "@pages/settings/ui/employees/model/commission.column";
import { $favoritesColumn } from "@pages/settings/ui/employees/model/favorites.column";
import { $permissionsColumn } from "@pages/settings/ui/employees/model/permissions.column";
import { $specialPermissionsColumn } from "@pages/settings/ui/employees/model/special-permissions.column";
import { $viewColumn } from "@pages/settings/ui/employees/model/view.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine(
  [
    $accrualsColumn,
    $commissionColumn,
    $permissionsColumn,
    $specialPermissionsColumn,
    $viewColumn,
    $favoritesColumn,
  ],
  ([
    accrualsColumnValue,
    commissionColumnValue,
    permissionsColumn,
    specialPermissionsColumn,
    viewColumn,
    favoritesColumn,
  ]) => [
    accrualsColumnValue,
    commissionColumnValue,
    permissionsColumn,
    specialPermissionsColumn,
    viewColumn,
    favoritesColumn,
  ],
).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
