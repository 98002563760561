import { InputsFieldProps } from "@shared/types";
import clsx from "clsx";
import { FC, Fragment } from "react";

import styles from "./styles.module.scss";

export const InputsField: FC<InputsFieldProps> = ({
  inputsValues,
  onChangeValues,
  placeholders,
  isEditing,
}) => {
  const handleFieldChange = (value: string, index: number) => {
    onChangeValues && onChangeValues(value, index);
  };
  const getClassNames = (index: number) =>
    clsx(styles.input_field, {
      [styles.small_field]: index != inputsValues!.length - 1,
      [styles.border]: isEditing,
    });

  return (
    <div className={styles.wrapper}>
      {inputsValues?.map(
        (value, index) =>
          value && (
            <Fragment key={index}>
              {index !== 0 && <span className={styles.separator} />}
              <div className={getClassNames(index)}>
                <input
                  className={getClassNames(index)}
                  value={value}
                  placeholder={placeholders![index]}
                  onChange={(e) =>
                    handleFieldChange(e.currentTarget.value, index)
                  }
                />
              </div>
            </Fragment>
          ),
      )}
    </div>
  );
};
