import { sessionGetFx } from "@shared/api";
import { appStarted, routes, STORAGE_TOKEN } from "@shared/config";
import { redirect } from "atomic-router";
import { createEvent, createStore, restore, sample } from "effector";
import { createEffect } from "effector/compat";
import { persist } from "effector-storage/local";

export type Session = {
  FirstName: string;
  ID: number;
  LastName: string;
  Login: string;
  PatronymicName: string;
};

export const userGet = createEffect(sessionGetFx);
export const firstUserGet = createEffect(sessionGetFx);
export const repeatRequest = createEvent();
export const firstRequest = createEvent();

export const $token = createStore("");
export const tokenReceived = createEvent<string>();
export const tokenExpired = createEvent();

$token.on(tokenReceived, (_, token) => token).reset(tokenExpired);

export const $isAuth = $token.map((state) => !!state);

persist({
  store: $token,
  key: STORAGE_TOKEN,
  serialize(value) {
    return value;
  },
  deserialize(value) {
    return value;
  },
});

redirect({
  clock: tokenExpired,
  route: routes.auth.signIn,
});

export const $session = restore<Session>(userGet, null);

export const $pending = firstUserGet.pending;

sample({
  clock: appStarted,
  target: firstRequest,
});

sample({
  clock: firstRequest,
  source: $token,
  filter: $isAuth,
  target: firstUserGet,
});

sample({
  clock: repeatRequest,
  source: $token,
  filter: $isAuth,
  target: userGet,
});

setInterval(() => {
  repeatRequest();
}, 55000);

if (window.location.toString().split("/").pop() == "sign-in") {
  redirect({
    clock: firstUserGet.doneData,
    route: routes.private.home,
  });
}
