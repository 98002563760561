import { Field } from "@shared/types";
import { columnModel } from "@widgets/column";
import clsx from "clsx";
import { useUnit } from "effector-react";
import { FC, FormEvent, useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";

type TextAreaFieldProps = {
  field: Field;
  value: string;
  onChange: (newValue: string) => void;
  isEditing?: boolean;
  indexField?: number;
};

export const TextAreaField: FC<TextAreaFieldProps> = ({
  field,
  value,
  onChange,
  indexField,
  isEditing,
}) => {
  const [editingOpenFieldIndex, pointerFields] = useUnit([
    columnModel.$editingOpenFieldIndex,
    columnModel.$pointerFields,
  ]);
  const divRef = useRef<HTMLDivElement>(null);
  const [focus, setFocus] = useState<boolean>(false);

  const checkPointer = () => {
    if (divRef.current) {
      if (divRef.current.scrollHeight > divRef.current.clientHeight) {
        !pointerFields.includes(field) &&
          columnModel.setPointerFields([...pointerFields, field]);
      } else if (editingOpenFieldIndex != indexField)
        columnModel.setPointerFields(
          pointerFields.filter((item) => item != field),
        );
    }
  };

  const handleBlur = (event: FormEvent<HTMLDivElement>) => {
    if (divRef.current) {
      onChange(event.currentTarget.innerText);
    }
    setFocus(false);
  };

  const handleChange = () => {
    const height = divRef.current?.getBoundingClientRect().height;
    height && height > 72 && columnModel.setFieldHeight(height + 30);
    height && height < 72 && columnModel.setFieldHeight(height);
  };

  useEffect(() => {
    if (divRef.current) {
      divRef.current.textContent = value;
    }
  }, [value]);

  useEffect(() => {
    const height = divRef.current?.getBoundingClientRect().height;
    if (height && height > 71 && editingOpenFieldIndex == indexField)
      columnModel.setFieldHeight(height + 30);
    if (height && height < 71 && editingOpenFieldIndex == indexField)
      columnModel.setFieldHeight(71);
    checkPointer();
  }, [editingOpenFieldIndex]);

  useEffect(() => {
    indexField &&
      editingOpenFieldIndex != indexField &&
      columnModel.setOpenFieldIndex(indexField);
  }, [isEditing]);

  return (
    <div
      className={clsx(
        styles.wrapper,
        isEditing && styles.border,
        (editingOpenFieldIndex == indexField || isEditing) && styles.active,
      )}
    >
      <div>
        {value === "" && !focus && (
          <span className={styles.placeholder}>...</span>
        )}
        <div
          className={styles.input}
          ref={divRef}
          onFocus={() => setFocus(true)}
          onBlur={(e) => handleBlur(e)}
          onInput={() => handleChange()}
          contentEditable
          role="textbox"
        />
      </div>
    </div>
  );
};
