import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const setFields = createEvent<Field[]>();

export const $fields = createStore<Field[]>([
  {
    type: "select",
    placeholders: ["...", "...", undefined],
    values: [[], [], undefined],
    selects: [0, undefined, undefined],
  },
  {
    type: "select",
    placeholders: ["...", "...", undefined],
    values: [[], [], undefined],
    selects: [0, undefined, undefined],
  },
]).on(setFields, (_, field) => field);
export const $payrollsColumn = createStore<ColumnProps>({
  title: "Начисления",
  renderFields: $fields.getState(),
  setFields: setFields,
  actionPlaceholder: "Выберите данные",
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "Начисления",
    deleteBlock: handleDeleteBlock,
  },
}).on($fields, (payrollsColumn, fields) => ({
  ...payrollsColumn,
  renderFields: fields,
}));
