import { AnimatePresence, motion } from "framer-motion";
import { FC } from "react";
import { RotatingLines } from "react-loader-spinner";

import styles from "./styles.module.scss";

type LoaderProps = {
  pending: boolean;
};

export const Loader: FC<LoaderProps> = ({ pending }) => {
  return (
    <AnimatePresence>
      {pending && (
        <motion.div
          key="loader"
          className={styles.loader}
          animate={{ opacity: 1, transition: { duration: 0.5 } }}
          exit={{
            opacity: 0,
            transition: { duration: 0.5, delay: 1 },
          }}
        >
          <RotatingLines
            strokeColor="var(--color-active)"
            strokeWidth="4"
            animationDuration="0.75"
            width="24"
          />
          Пожалуйста, подождите. Идёт загрузка данных.
        </motion.div>
      )}
    </AnimatePresence>
  );
};
