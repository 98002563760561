import { Icon } from "@shared/ui";
import clsx from "clsx";
import { FC } from "react";

import styles from "./styles.module.scss";

type IconFieldProps = {
  isEditing?: boolean;
};

export const IconField: FC<IconFieldProps> = ({ isEditing }) => {
  return (
    <div className={styles.item}>
      <span className={styles.text}>Иконка</span>
      <div className={styles.separator} />
      <div className={clsx(styles.icon, { [styles.border]: isEditing })}>
        <Icon name="upload" />
      </div>
    </div>
  );
};
