import { objectsModel } from "@pages/settings/ui/objects";
import { Column } from "@widgets/column";
import { useUnit } from "effector-react";

export const Objects = ({}) => {
  const [columns] = useUnit([objectsModel.$columns]);

  console.log({ columns });

  return columns.map((column) => (
    <Column
      key={column.title}
      {...column}
      context={column.context}
      model={objectsModel}
    />
  ));
};
