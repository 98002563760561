import * as ContextMenu from "@radix-ui/react-context-menu";
import { ContextMenuItemProps } from "@radix-ui/react-context-menu";
import { FC } from "react";

import styles from "./styles.module.scss";

export const ContextItem: FC<ContextMenuItemProps> = ({
  children,
  ...other
}) => {
  return (
    <ContextMenu.Item {...other} className={styles.context_item}>
      {children}
    </ContextMenu.Item>
  );
};
