import { departmentModel } from "@entities/department";
import { employeeModel } from "@entities/employee";
import { entityModel } from "@entities/entity";
import { routes } from "@shared/config";
import { chainRoute } from "atomic-router";

export const currentRoute = routes.private.settings;

chainRoute({
  route: currentRoute,
  beforeOpen: departmentModel.departmentsGet,
});

chainRoute({
  route: currentRoute,
  beforeOpen: employeeModel.employeesGet,
});

chainRoute({
  route: currentRoute,
  beforeOpen: entityModel.entitiesGet,
});
