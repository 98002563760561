import { notFoundModel } from "@pages/not-found";
import { useEffect } from "react";

import styles from "./styles.module.scss";
export const NotFoundPage = () => {
  useEffect(() => {
    notFoundModel.pageMounted();
  }, []);

  return <div className={styles.greetings}>СТРАНИЦА НЕ НАЙДЕНА</div>;
};
