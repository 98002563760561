import { wsController } from "@app/providers/with-websocket";
import { departmentModel } from "@entities/department";
import { employeeModel } from "@entities/employee";
import { entityModel } from "@entities/entity";
import { sectionModel } from "@entities/section";
import { sessionModel } from "@entities/session";
import { STORAGE_TOKEN } from "@shared/config";
import { createEffect } from "effector";

const getToken = () => `${localStorage.getItem(STORAGE_TOKEN)}`;

export const sessionGetFx = createEffect<any, sessionModel.Session, Error>(
  async () =>
    wsController
      .send({
        Controller: "Authorization",
        Action: "Get",
        Data: [{ Token: getToken() }],
      })
      .then((response) => response[0]),
);

export const sectionsGetFx = createEffect<any, sectionModel.Section[], Error>(
  async () =>
    wsController.send({
      Controller: "Section",
      Action: "GetList",
      Data: [{ Token: getToken() }],
    }),
);

export const sectionsWithEmployeeGetFx = createEffect<
  any,
  sectionModel.EmployeeSection[],
  Error
>(async () =>
  wsController.send({
    Controller: "Department",
    Action: "GetListWithEmployee",
    Data: [{ Token: getToken() }],
  }),
);

export const entitiesGetFx = createEffect<any, entityModel.Entity[], Error>(
  async () =>
    wsController.send({
      Controller: "Object",
      Action: "GetList",
      Data: [{ Token: getToken() }],
    }),
);

export const employeesGetFx = createEffect<
  any,
  employeeModel.Employee[],
  Error
>(async () =>
  wsController.send({
    Controller: "Employee",
    Action: "GetList",
    Data: [{ Token: getToken() }],
  }),
);

type AuthFormProps = {
  Login: string;
  Password: string;
};

export const authFx = createEffect<AuthFormProps, any, Error>(
  async ({ Login, Password }) =>
    wsController.send({
      Controller: "Authentication",
      Action: "Login",
      Data: [
        {
          Keep: false,
          Login,
          Password,
        },
      ],
    }),
);

export const departmentsGetFx = createEffect<
  any,
  departmentModel.Department[],
  Error
>(async () =>
  wsController.send({
    Controller: "Department",
    Action: "GetList",
    Data: [{ Token: getToken() }],
  }),
);
