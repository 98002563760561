import { sessionModel } from "@entities/session";
import { sectionsGetFx, sectionsWithEmployeeGetFx } from "@shared/api";
import { appStarted } from "@shared/config";
import { Nullable } from "@shared/types";
import { createEffect, createStore, restore, sample } from "effector";

export type Employee = {
  ID: number;
  DepartmentID: number;
  ManualID: number;
  LastName: string;
  FirstName: string;
  PatronymicName: string;
  Active: boolean;
  SortNumber: number;
  Position: string;
  PhoneNumber: string;
  Telegram: string;
  Email: string;
  Login: string;
  Password: string;
};

export type EmployeeSection = {
  ID: number;
  Name: string;
  Active: boolean;
  SortNumber: number;
  Employee: Employee[] | null;
};

export type Section = {
  ID: number;
  Name: string;
  Title: string;
  Active: boolean;
  SortNumber: number;
  Icon: string;
  Sections: Section[];
};

export const sectionsGet = createEffect(sectionsGetFx);
export const sectionsWithEmployeeGet = createEffect(sectionsWithEmployeeGetFx);

export const $sections = restore<Section[]>(
  sectionsGet.done.map((result) => result.result.reverse()),
  null,
);

export const $settingsSections = createStore<Nullable<Section[]>>(null);

$settingsSections.on(
  $sections.map(
    (sections) =>
      sections?.find((section) => section.Name === "settings")?.Sections ??
      null,
  ),
  (_, settingsSections) => settingsSections,
);

export const $pending = sectionsGet.pending;

export const $employeeSections = restore<EmployeeSection[]>(
  sectionsWithEmployeeGet.done.map((result) => result.result.reverse()),
  null,
);

sample({
  clock: appStarted,
  filter: sessionModel.$isAuth,
  target: sectionsGet,
});
