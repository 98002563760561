import { $payrollsColumn } from "@pages/settings/ui/formulas/model/payrolls.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine([$payrollsColumn], ([payrollsColumn]) => [payrollsColumn]).watch(
  (combinedColumns) => {
    setColumns(combinedColumns);
  },
);
