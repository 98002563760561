import { InputField } from "@features/column/fields/input";
import { SelectFieldProps } from "@shared/types";
import {
  RadixSelect as Wrapper,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@shared/ui";
import clsx from "clsx";
import { FC, Fragment } from "react";

import styles from "./styles.module.scss";

export const SelectField: FC<SelectFieldProps> = ({
  title,
  placeholders,
  values,
  selects,
  onChangeSelected,
  isEditing,
  inputField,
  textAlign,
  solidMod,
  noArrow,
}) => {
  const handleSelect = (value: number, index: number) => {
    onChangeSelected && onChangeSelected(value, index);
  };
  const getClassNames = (index: number) =>
    clsx(styles.selects, {
      [styles.small_field]: index == 1 || (index == 0 && values[1]),
      [styles.border]: isEditing,
    });

  return (
    <div className={clsx(styles.field, { [styles.solid_mod]: solidMod })}>
      {title && <span className={styles.text}>{title}</span>}

      {title && <div className={styles.separator} />}

      <form className={styles.select} name="selects">
        {values?.map(
          (field, index) =>
            field && (
              <Fragment key={index}>
                {index !== 0 && <span className={styles.separator} />}
                <div className={getClassNames(index)}>
                  <Wrapper
                    defaultValue={
                      (selects && selects[index]?.toString()) ?? undefined
                    }
                    onValueChange={(value) =>
                      handleSelect(parseInt(value), index)
                    }
                  >
                    <SelectTrigger
                      className={clsx(styles.trigger, {
                        [styles.no_arrow]: noArrow,
                      })}
                    >
                      <SelectValue
                        placeholder={placeholders && placeholders[index]}
                      />
                    </SelectTrigger>
                    <SelectContent
                      position="popper"
                      side="bottom"
                      sideOffset={0}
                      alignOffset={0}
                      avoidCollisions={false}
                    >
                      <SelectGroup>
                        {field?.map((item, index) => (
                          <SelectItem key={index} value={index.toString()}>
                            {item}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Wrapper>
                </div>
              </Fragment>
            ),
        )}
        {inputField?.value != undefined && (
          <span className={styles.separator} />
        )}
        {inputField?.value != undefined && (
          <div
            style={{ width: "100%", height: "100%" }}
            className={clsx({
              [styles.border]: isEditing,
            })}
          >
            <InputField
              textAlign={textAlign}
              value={inputField.value as string}
              onChange={inputField.onChange}
              isEditing={isEditing}
              className={styles.input_field}
              noBorder={true}
            />
          </div>
        )}
      </form>
    </div>
  );
};
