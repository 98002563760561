import { routes } from "@shared/config";
import { Field, Nullable } from "@shared/types";
import { createEvent, createStore, sample } from "effector";

export const setEditingIndex = createEvent<number>();
export const clearIndex = createEvent();
export const $editingIndex = createStore<Nullable<number>>(null)
  .on(setEditingIndex, (_, id) => id)
  .on(clearIndex, (_) => null);

export const setOpenFieldIndex = createEvent<number>();
export const $editingOpenFieldIndex = createStore<number>(-1).on(
  setOpenFieldIndex,
  (currentIndex, newIndex) => {
    return currentIndex === newIndex ? -1 : newIndex;
  },
);

export const setFieldHeight = createEvent<number>();
export const $fieldHeight = createStore<number>(0).on(
  setFieldHeight,
  (_, height) => height,
);

export const setPointerFields = createEvent<Field[]>();
export const $pointerFields = createStore<Field[]>([]).on(
  setPointerFields,
  (_, field) => field,
);

sample({
  clock: routes.private.finance.updated,
  target: clearIndex,
});

sample({
  clock: routes.private.settings.updated,
  target: clearIndex,
});
