import { routes } from "@shared/config";
import { redirect } from "atomic-router";
import { createEvent } from "effector";

export interface Section {
  ID: number;
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt: string | null;
  Name: string;
  Title: string;
  Active: number;
  SortNumber: number;
  ParentID: number;
  Icon: string;
  Sections: Section[];
}

const getRoute = (route: string) => {
  if (route === "finance") return routes.private.finance;
  if (route === "music") return routes.private.music;
  if (route === "managers") return routes.private.managers;
  return routes.private.finance;
};

export const sectionsItemClick = createEvent<{
  route: string;
  catalog: string;
}>();
const link = createEvent();
sectionsItemClick.watch(({ route, catalog }) => {
  redirect({
    clock: link,
    params: { catalog },
    route: getRoute(route),
  });
  link();
});
