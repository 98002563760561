import { $sectionsColumn } from "@pages/settings/ui/sections/model/sections.column";
import { $settingSectionsColumn } from "@pages/settings/ui/sections/model/setting-sections.column";
import { $settingSubSectionsColumn } from "@pages/settings/ui/sections/model/setting-sub-sections.column";
import { $subSectionsColumn } from "@pages/settings/ui/sections/model/sub-sections.column";
import { $systemMenuColumn } from "@pages/settings/ui/sections/model/system-menu.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine(
  [
    $systemMenuColumn,
    $sectionsColumn,
    $subSectionsColumn,
    $settingSectionsColumn,
    $settingSubSectionsColumn,
  ],
  ([
    systemMenuColumn,
    sectionsColumn,
    subSectionsColumn,
    settingSectionsColumn,
    settingSubSectionsColumn,
  ]) => [
    systemMenuColumn,
    sectionsColumn,
    subSectionsColumn,
    settingSectionsColumn,
    settingSubSectionsColumn,
  ],
).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
