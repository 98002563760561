import { ColumnProps, Field } from "@shared/types";
import { createEvent, createStore } from "effector";

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "nested",
    title: "Выручка",
    value: "312 000 320,89",
    sections: [
      {
        type: "nested",
        title: "Банк",
        value: "2 321 223,00",
        icon: "bank",
        sections: [
          {
            type: "inputs",
            placeholders: ["...", "...", "..."],
            inputsValues: ["1", "2", "3"],
          },
          {
            type: "inputs",
            placeholders: ["...", "...", "..."],
            inputsValues: ["1", "2", "3"],
          },
        ],
      },
      {
        type: "nested",
        title: "Касса",
        value: "123 339,23",
        icon: "wallet",
        sections: [
          {
            type: "inputs",
            placeholders: ["...", "...", "..."],
            inputsValues: ["1", "2", "3"],
          },
          {
            type: "inputs",
            placeholders: ["...", "...", "..."],
            inputsValues: ["1", "2", "3"],
          },
        ],
      },
    ],
  },
  {
    type: "nested",
    title: "Расход",
    value: "7 232 254,22",
    sections: [
      {
        type: "nested",
        title: "Заработная плата",
        value: "123 339,23",
        icon: "cash",
        sections: [
          {
            type: "nested",
            title: "Оклад",
            value: "123 339,23",
            sections: [
              {
                type: "inputs",
                placeholders: ["...", "...", "..."],
                inputsValues: ["1", "2", "3"],
              },
            ],
          },
          {
            type: "nested",
            title: "Комиссионные",
            value: "10 213,48",
            sections: [
              {
                type: "inputs",
                placeholders: ["...", "...", "..."],
                inputsValues: ["1", "2", "3"],
              },
            ],
          },
        ],
      },
      {
        type: "nested",
        title: "Налоги",
        value: "9 541,23",
        icon: "tax",
        sections: [
          {
            type: "nested",
            title: "УСН",
            value: "6 233,00",
            sections: [
              {
                type: "inputs",
                placeholders: ["...", undefined, "..."],
                inputsValues: ["1", undefined, "3"],
              },
            ],
          },
          {
            type: "nested",
            title: "ОМС, ОПС",
            value: "3 339,23",
            sections: [
              {
                type: "inputs",
                placeholders: ["...", "...", "..."],
                inputsValues: ["1", "2", "3"],
              },
            ],
          },
        ],
      },
      {
        type: "nested",
        title: "Услуги",
        value: "80 240,20",
        icon: "services",
        sections: [
          {
            type: "nested",
            title: "...",
            value: "6 233,00",
            sections: [
              {
                type: "inputs",
                placeholders: ["...", undefined, "..."],
                inputsValues: ["1", undefined, "3"],
              },
            ],
          },
        ],
      },
      {
        type: "nested",
        title: "Кассы",
        value: "9 002 213,48",
        icon: "counter",
        sections: [
          {
            type: "nested",
            title: "Продакшн",
            value: "12 500,00",
            sections: [
              {
                type: "inputs",
                placeholders: ["...", undefined, "..."],
                inputsValues: ["1", undefined, "3"],
              },
            ],
          },
          {
            type: "nested",
            title: "Альперов",
            value: "3 760,00",
            sections: [
              {
                type: "inputs",
                placeholders: ["...", undefined, "..."],
                inputsValues: ["1", undefined, "3"],
              },
            ],
          },
        ],
      },
      {
        type: "nested",
        title: "Прочее",
        value: "0,48",
        icon: "salary",
        sections: [
          {
            type: "nested",
            title: "...",
            value: "0",
            sections: [
              {
                type: "select",
                placeholders: ["...", undefined, undefined],
                values: [[], undefined, undefined],
                selects: [0, undefined, undefined],
                inputField: {
                  value: "",
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "nested",
    title: "Доход объектов",
    value: "2 321 223,00",
    sections: [],
  },
  {
    type: "nested",
    title: "Доход А1",
    value: "123 339,23",
    sections: [],
  },
]).on(setFields, (_, field) => field);

export const $foodColumn = createStore<ColumnProps>({
  title: "28",
  width: 358,
  renderFields: $fields.getState(),
  isAlwaysOpen: true,
  actionDisable: true,
  actionStatic: {
    icon: "food_city",
    text: "01.2022",
  },
  columnType: "nested",
  setFields: setFields,
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "food_city",
  },
}).on($fields, (foodColumn, fields) => ({
  ...foodColumn,
  renderFields: fields,
}));
