import { Icon } from "@shared/ui";
import clsx from "clsx";
import { Dispatch, FC, SetStateAction } from "react";

import styles from "./styles.module.scss";

type TitleProps = {
  title: string;
  rolled?: boolean;
  setRoll?: Dispatch<SetStateAction<boolean>>;
};

export const Title: FC<TitleProps> = ({ title, rolled, setRoll }) => {
  return (
    <div
      className={clsx(styles.title, { [styles.rolled_title]: rolled })}
      onClick={() => {
        if (setRoll) {
          setRoll(!rolled);
        }
      }}
    >
      {rolled ? (
        <Icon name="vector" />
      ) : (
        <span className={styles.title_text}>{title}</span>
      )}
    </div>
  );
};
