import { Calculation } from "@pages/finance/ui/calculation";
import { Counter } from "@pages/finance/ui/counter";
import { Counter1 } from "@pages/finance/ui/counter1";
import { Counter2 } from "@pages/finance/ui/counter2";
import { Wage } from "@pages/finance/ui/wage";
import { pageModel } from "@shared/lib/page";
import { useRouter } from "atomic-router-react";
import clsx from "clsx";
import { useUnit } from "effector-react";
import { FC, useEffect } from "react";

import styles from "./styles.module.scss";

export const FinancePage = ({}) => {
  const { $path } = useRouter();
  const [activePage, page, path] = useUnit([
    pageModel.$activePage,
    pageModel.$selectSub,
    $path,
  ]);

  const tabs = {
    wage: Wage,
    counter: page == 1 ? Counter : page == 2 ? Counter2 : Counter1,
    calculation: Calculation,
  } as { [key: string]: FC };

  const formattedPath = path.split("/");

  useEffect(() => {
    if (formattedPath && formattedPath.length > 2) {
      pageModel.activePageChanged(formattedPath[2] as any);
    }
  }, [formattedPath]);

  const CurrentView = tabs[activePage || (formattedPath && formattedPath[2])];

  return (
    <div className={clsx(styles.finance, CurrentView != null && styles.active)}>
      {CurrentView && <CurrentView />}
    </div>
  );
};
