import { sectionModel } from "@entities/section";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useOutsideClick } from "@shared/hooks/outside-click";
import { pageModel } from "@shared/lib/page";
import { Nullable } from "@shared/types";
import { sidebarModel } from "@widgets/nav/sidebar";
import { useRouter } from "atomic-router-react";
import clsx from "clsx";
import { useUnit } from "effector-react";
import { useRef, useState } from "react";

import styles from "./styles.module.scss";

export const Sections = () => {
  const { $path } = useRouter();
  const [changeCatalog, path, sections] = useUnit([
    sidebarModel.sectionsItemClick,
    $path,
    sectionModel.$sections,
  ]);

  const [openedSubmenu, setOpenedSubmenu] = useState<Nullable<number>>(null);
  const filteredSections = sections?.filter(
    (section) => section.Name !== "settings",
  );
  const container = useRef<HTMLDivElement>(null);

  const handleClick = (id: number, name: string) => {
    setOpenedSubmenu(id);
    pageModel.activeTabChanged(name.toLowerCase() + "_sub");
    pageModel.setActiveSub(false);
  };

  const handleSubMenuClick = (route: string, name: string) => {
    changeCatalog({ route: route, catalog: name });
    setOpenedSubmenu(null);
    pageModel.setActiveSub(false);
  };

  useOutsideClick(container, () => setOpenedSubmenu(null));

  const renderSubMenu = (elem: sectionModel.Section) => {
    return (
      <DropdownMenu.Root open={openedSubmenu === elem.ID} key={elem.ID}>
        <DropdownMenu.Trigger
          asChild
          key={elem.ID}
          className={styles.sections_item}
          onClick={() => handleClick(elem.ID, elem.Name)}
        >
          <div
            className={clsx(styles.wrapper_icon, {
              [styles.sections_item_active]: path.split("/")[1] == elem.Name,
            })}
            dangerouslySetInnerHTML={{ __html: elem.Icon }}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className={styles.submenu}
            side="right"
            align="start"
            sideOffset={23}
            ref={container}
          >
            {elem.Sections.map((item, key) => (
              <span
                onClick={() => handleSubMenuClick(elem.Name, item.Name)}
                className={clsx(styles.submenu_item, {
                  [styles.submenu_item_active]:
                    path.split("/").pop() == item.Name,
                })}
                key={key}
              >
                <span dangerouslySetInnerHTML={{ __html: item.Icon }} />

                <span>{item.Title}</span>
              </span>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );
  };

  return (
    <div className={styles.sections}>
      {filteredSections?.map(renderSubMenu)}
    </div>
  );
};
