import { getSelectedIndices } from "@shared/lib/getIndexes";
import { Column } from "@widgets/column";
import { useUnit } from "effector-react";
import React, { useRef } from "react";

import { counterModel } from "../index";
import styles from "./styles.module.scss";

export const Counter2 = () => {
  const [columns] = useUnit([counterModel.$columns]);
  const selectedIndices = columns.flatMap(getSelectedIndices);
  const scrollRef = useRef<HTMLDivElement>(null);
  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    const container = scrollRef.current;
    if (container && event.clientY <= 120) {
      container.scrollLeft += event.deltaY;
    }
  };

  return (
    <div className={styles.scroll} ref={scrollRef} onWheel={handleWheel}>
      {columns.map((column) => (
        <Column
          key={column.title}
          {...column}
          context={column.context}
          model={counterModel}
          indexesToShow={selectedIndices}
          isColumnMaybeDelete={false}
          useScrollY={false}
        />
      ))}
    </div>
  );
};
