export * as signInModel from "./model";
export { SignInPage } from "./ui";

import { currentRoute } from "./model";
import { SignInPage } from "./ui";

export const SignInRoute = {
  view: SignInPage,
  route: currentRoute,
};
