import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "select",
    placeholders: ["...", undefined, undefined],
    values: [[], undefined, undefined],
    selects: [0, undefined, undefined],
  },
  {
    type: "select",
    placeholders: ["...", undefined, undefined],
    values: [[], undefined, undefined],
    selects: [0, undefined, undefined],
  },
]).on(setFields, (_, field) => field);

export const $commissionColumn = createStore<ColumnProps>({
  title: "Комиссия",
  width: 300,
  renderFields: $fields.getState(),
  setFields: setFields,
  actionPlaceholder: "Выберите данные",
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "Комиссия",
    deleteBlock: handleDeleteBlock,
  },
}).on($fields, (commissionColumn, fields) => ({
  ...commissionColumn,
  renderFields: fields,
}));
