import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const indexChanged = createEvent<number[]>();
export const $indexes = createStore<number[]>([]).on(
  indexChanged,
  (_, newIndex) => newIndex,
);

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "numerical",
    value: "",
    noContext: true,
  },
  {
    type: "numerical",
    value: "1 224 000,00",
  },
  {
    type: "numerical",
    value: "1 988,23",
  },
  {
    type: "numerical",
    value: "20 000,00",
  },
  {
    type: "numerical",
    value: "458,98",
  },
]).on(setFields, (_, field) => field);

export const $sumColumn = createStore<ColumnProps>({
  title: "Сумма",
  width: 130,
  fieldHeight: 72,
  renderFields: $fields.getState(),
  setFields: setFields,
  actionHide: true,
  solidMod: true,
  isAlwaysOpen: true,
  colorAlternation: true,
  textAlign: "right",
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "Сумма",
    deleteBlock: handleDeleteBlock,
  },
  headerFilter: {
    type: "numerical-search",
    list: $fields.getState().map((cell) => cell.value),
    selects: $indexes.getState(),
    setDates: indexChanged,
  },
})
  .on($fields, (sumColumn, fields) => ({
    ...sumColumn,
    renderFields: fields,
    headerFilter: {
      ...sumColumn.headerFilter,
      list: fields.map((cell) => cell.value),
    },
  }))
  .on($indexes, (sumColumn, indexes) => ({
    ...sumColumn,
    headerFilter: {
      ...sumColumn.headerFilter,
      selects: indexes,
    },
  }));
