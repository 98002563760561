import { Nullable } from "@shared/types";
import { Icon } from "@shared/ui";
import React, { FC, useMemo, useState } from "react";

import styles from "./styles.module.scss";

interface SelectSearch {
  list?: Nullable<any[]>;
  popupRef: React.RefObject<HTMLDivElement>;
  setSelected?: any;
  icons?: { multi: boolean; star: boolean };
}

export const SelectSearch: FC<SelectSearch> = ({
  list,
  popupRef,
  setSelected,
  icons,
}) => {
  const [searchValues, setSearchValues] = useState<{
    ID: number | undefined;
    Name: string;
  }>({
    ID: undefined,
    Name: "",
  });
  const [isMulti, setIsMulti] = useState(false);

  const filterData = useMemo(() => {
    const search = searchValues.Name.toLowerCase();
    return (list || []).filter((elem) => {
      if (searchValues.ID !== undefined && elem.ID !== searchValues.ID) {
        return false;
      }
      return !(search !== "" && !elem.Name.toLowerCase().includes(search));
    });
  }, [list, searchValues.ID, searchValues.Name]);

  return (
    <div className={styles.selectSearch} ref={popupRef}>
      <div className={styles.selectSearch__search}>
        <div className={styles.inputContainer}>
          <input
            type="text"
            placeholder="..."
            value={
              searchValues.ID !== undefined ? searchValues.ID.toString() : ""
            }
            name="ID"
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                ID: parseInt(e.target.value) || undefined,
              }))
            }
          />
        </div>

        <div className={styles.inputContainer}>
          <input
            type="text"
            placeholder="..."
            value={searchValues.Name}
            name="Name"
            autoComplete="off"
            onChange={(e) =>
              setSearchValues((prev) => ({
                ...prev,
                Name: e.target.value,
              }))
            }
          />
        </div>

        {icons && (
          <div className={styles.iconsContainer}>
            {icons.star && (
              <div>
                <Icon name="star" />
              </div>
            )}

            {icons.multi && (
              <div
                style={{ color: isMulti ? "var(--color-active)" : "" }}
                onClick={() => setIsMulti((prev) => !prev)}
              >
                <Icon name="multy" />
              </div>
            )}
          </div>
        )}
      </div>

      <div className={styles.selectSearch__divider} />

      <div className={styles.selectSearch__body}>
        <div className={styles.select_items}>
          {(filterData || []).map((elem) => (
            <div
              className={styles.listItem}
              key={elem.ID ?? Math.random()}
              onClick={() => {
                if (setSelected) {
                  setSelected({ id: elem.ID as number, name: elem.Name });
                }
              }}
            >
              <div>{elem.ID}</div>
              <div>{elem.Name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
