import { Departments } from "@pages/settings/ui/departments";
import { Employee } from "@pages/settings/ui/employee/ui";
import { Employees } from "@pages/settings/ui/employees";
import { Entity } from "@pages/settings/ui/entity";
import { Formulas } from "@pages/settings/ui/formulas";
import { Objects } from "@pages/settings/ui/objects";
import { Payrolls } from "@pages/settings/ui/payrolls";
import { Permissions } from "@pages/settings/ui/permissions";
import { Sections } from "@pages/settings/ui/sections";
import { Types } from "@pages/settings/ui/types";
import { menuModel } from "@widgets/menu";
import { Menu } from "@widgets/menu";
import { useUnit } from "effector-react";
import { useRef } from "react";

import styles from "./styles.module.scss";

type Tab = {
  [key: string]: {
    [key: string]: any;
  };
};

const tabs: Tab = {
  objects: {
    objects: Entity,
  },
  employee: {
    employees: Employees,
  },
  dictionary: {
    permissions: Permissions,
    payrolls: Payrolls,
    formulas: Formulas,
    departments: Departments,
    sections: Sections,
    types: Types,
    objects: Objects,
    employee: Employee,
  },
  registry: {
    regions: Types,
  },
};

export const SettingsPage = () => {
  const [activeTab] = useUnit([menuModel.$activeTab]);

  const key = activeTab && Object.keys(activeTab).toString();
  const value = activeTab && Object.values(activeTab)[0]?.name;

  const CurrentView =
    key == "objects"
      ? tabs[key][key]
      : key == "employee"
        ? tabs[key]["employees"]
        : key && value && tabs[key][value];

  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.settings}>
      <Menu menu="Settings" />

      <div
        className={styles.scroll}
        ref={scrollRef}
        onWheel={(event) => {
          const container = scrollRef.current;
          if (container && event.clientY <= 120) {
            container.scrollLeft += event.deltaY;
          }
        }}
      >
        {CurrentView && <CurrentView />}
      </div>
    </div>
  );
};
