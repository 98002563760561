import { $appointmentsColumn } from "@pages/finance/ui/counter1/model/appointments.column";
import { $dateColumn } from "@pages/finance/ui/counter1/model/date.column";
import { $employeeColumn } from "@pages/finance/ui/counter1/model/employee.column";
import { $objectColumn } from "@pages/finance/ui/counter1/model/object.column";
import { $periodColumn } from "@pages/finance/ui/counter1/model/period.column";
import { $roleColumn } from "@pages/finance/ui/counter1/model/role.column";
import { $sumColumn } from "@pages/finance/ui/counter1/model/sum.column";
import { $technicalDepartmentColumn } from "@pages/finance/ui/counter1/model/technical-department.column";
import { $tpColumn } from "@pages/finance/ui/counter1/model/tp.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine(
  [
    $technicalDepartmentColumn,
    $periodColumn,
    $dateColumn,
    $sumColumn,
    $appointmentsColumn,
    $employeeColumn,
    $objectColumn,
    $tpColumn,
    $roleColumn,
  ],
  ([
    technicalDepartmentColumn,
    periodColumn,
    dateColumn,
    sumColumn,
    appointmentsColumn,
    employeeColumn,
    objectColumn,
    tpColumn,
    roleColumn,
  ]) => [
    technicalDepartmentColumn,
    periodColumn,
    dateColumn,
    sumColumn,
    appointmentsColumn,
    employeeColumn,
    objectColumn,
    tpColumn,
    roleColumn,
  ],
).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
