import { useOutsideClick } from "@shared/hooks/outside-click";
import { Icon } from "@shared/ui";
import clsx from "clsx";
import { FC, useRef, useState } from "react";

import styles from "./styles.module.scss";

type BasicItem = {
  id: number | string;
  name: number | string;
  icon?: string;
};

type SelectProps = {
  list: BasicItem[];
  className?: string;
};

export const Select: FC<SelectProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>();

  const triggerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useOutsideClick(contentRef, () => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(event!.target as Node)
    ) {
      setIsOpen(false);
    }
  });

  return (
    <div className={clsx(styles.wrapper, props.className)}>
      <div className={styles.trigger}>
        {selected != undefined && <Icon name={props.list[selected].icon} />}
        <div ref={triggerRef} style={{ display: "flex" }}>
          <Icon name="triangle-down" onClick={() => setIsOpen(!isOpen)} />
        </div>
      </div>
      <div
        className={clsx(styles.content, { [styles.open]: isOpen })}
        ref={contentRef}
      >
        {props.list.map((item, index) => (
          <div
            key={index}
            className={clsx(styles.item, {
              [styles.selected]: selected == index,
            })}
            onClick={() => {
              setSelected(index);
              setIsOpen(false);
            }}
          >
            {item.icon && <Icon name={item.icon} />}
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
