import { $commonColumn } from "@pages/settings/ui/permissions/model/common.column";
import { $specialColumn } from "@pages/settings/ui/permissions/model/special.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine([$commonColumn, $specialColumn], ([commonColumn, specialColumn]) => [
  commonColumn,
  specialColumn,
]).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
