import { Context as ContextProps } from "@shared/types";
import { ContextItem, Icon } from "@shared/ui";
import { FC } from "react";

export const Add: FC<ContextProps> = ({ addBlock, subName }) => {
  return (
    <ContextItem onClick={addBlock}>
      <Icon name="add" /> Добавить {subName?.toLowerCase()}
    </ContextItem>
  );
};
