import { Context as ContextProps } from "@shared/types";
import { ContextItem, Icon } from "@shared/ui";
import { FC } from "react";

export const Delete: FC<ContextProps> = ({
  columnName,
  deleteBlock,
  model,
}) => {
  return (
    <ContextItem onClick={() => deleteBlock && deleteBlock(columnName, model)}>
      <Icon name="delete" /> Удалить блок
    </ContextItem>
  );
};
