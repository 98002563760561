import { $chargesColumn } from "@pages/settings/ui/types/model/charges.column";
import { $paymentColumn } from "@pages/settings/ui/types/model/payment.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine([$chargesColumn, $paymentColumn], ([chargesColumn, paymentColumn]) => [
  chargesColumn,
  paymentColumn,
]).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
