import { Field } from "@shared/types";
import clsx from "clsx";
import { ChangeEvent, FC } from "react";

import styles from "./styles.module.scss";

type InputFieldProps = {
  field?: Field;
  nestedIndex?: number;
  value: string;
  onChange?: (newValue: string, columnIndex: number) => any;
  onChangeNestedValue?: (
    newValue: string,
    columnIndex: number,
    field: Field,
  ) => any;
  isEditing?: boolean | undefined;
  columnIndex?: number;
  className?: string;
  solidMod?: boolean;
  textAlign?: "center" | "left" | "right";
  noContext?: boolean;
};

export const MonthInputField: FC<InputFieldProps> = ({
  field,
  value,
  onChange,
  onChangeNestedValue,
  isEditing,
  columnIndex,
  className,
  solidMod,
  textAlign,
  nestedIndex,
  noContext,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputMonth = e.target.value;
    let formattedMonth = inputMonth
      .replace(/[^0-9]/g, "")
      .replace(/(\d{2})(\d{0,4})/, (_, m, rest) => {
        const month = Math.min(parseInt(m, 10), 12).toString().padStart(2, "0");
        return rest.length > 0 ? `${month}.${rest}` : month;
      })
      .slice(0, 7);
    if (formattedMonth.length === 3 && inputMonth.length < value.length) {
      const lastChar = inputMonth.charAt(inputMonth.length - 1);
      if (lastChar !== ".") {
        formattedMonth = formattedMonth.slice(0, 2);
      }
    }
    onChangeNestedValue
      ? onChangeNestedValue(formattedMonth, nestedIndex as number, field!)
      : onChange && onChange(formattedMonth, columnIndex as number);
  };
  return (
    <div
      className={clsx(
        { [styles.field]: !solidMod },
        { [styles.solid_field]: solidMod },
        className,
      )}
    >
      <div
        className={clsx(styles.input, isEditing && styles.border, className)}
      >
        <input
          type="text"
          placeholder="мм.гггг"
          value={value}
          disabled={noContext ? false : !isEditing}
          onChange={handleChange}
          autoFocus={isEditing}
          style={{
            zIndex: isEditing || noContext ? 2 : 0,
            textAlign: textAlign ? textAlign : solidMod ? "center" : "right",
          }}
        />
      </div>
    </div>
  );
};
