import { sectionModel } from "@entities/section";
import { sessionModel } from "@entities/session";
import { routes } from "@shared/config";
import { checkOpenedRoute } from "@shared/lib/checkOpenedRoute";
import { pageModel } from "@shared/lib/page";
import { Icon } from "@shared/ui";
import { Menu } from "@widgets/menu";
import { Link } from "atomic-router-react";
import clsx from "clsx";
import { useUnit } from "effector-react";

import { Sections } from "./sections";
import styles from "./styles.module.scss";

const Burger = () => {
  const [page, activeSub, pagesSub, selectPage] = useUnit([
    pageModel.$activeTab,
    pageModel.$activeSub,
    pageModel.$pagesSubMenu,
    pageModel.$activePage,
  ]);

  return (
    <div
      className={clsx(styles.sidebar_burger, {
        [styles.active_link]: activeSub,
        [styles.disable_link]:
          !pagesSub[page] ||
          (selectPage && !pagesSub[page][selectPage]) ||
          !selectPage,
      })}
      onClick={() => {
        pageModel.setActiveSub(!activeSub);
      }}
    >
      <Icon name="burger" />

      {activeSub && <Menu menu="sub" />}
    </div>
  );
};

const BottomControls = () => {
  const [clearToken, sections] = useUnit([
    sessionModel.tokenExpired,
    sectionModel.$sections,
  ]);

  return (
    <div className={styles.bottom_block}>
      <Link
        to={routes.private.settings}
        className={clsx(styles.link, {
          [styles.active_link]: checkOpenedRoute(routes.private.settings),
        })}
      >
        {!!sections?.length && (
          <div
            onClick={() => {
              pageModel.setActiveSub(false);
              pageModel.activePageChanged("settings");
              pageModel.activeTabChanged("settings-sub");
            }}
            dangerouslySetInnerHTML={{
              __html:
                sections.find((elem) => elem.Name === "settings")?.Icon || "",
            }}
          />
        )}
      </Link>

      <div className={styles.divider} />

      <div className={styles.logout} onClick={clearToken}>
        <Icon name="exit" />
      </div>
    </div>
  );
};

export const Sidebar = () => {
  return (
    <aside className={styles.sidebar}>
      <Link className={styles.logo} to={routes.private.home}>
        <Icon name="sidebar-logo" />
      </Link>

      <Burger />

      <Sections />

      <BottomControls />
    </aside>
  );
};
