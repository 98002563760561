import { Checkbox, Icon } from "@shared/ui";
import {
  $formDisabled,
  $login,
  $password,
  formSubmitted,
  loginChanged,
  passwordChanged,
} from "@widgets/auth/model";
import { useUnit } from "effector-react";
import { useState } from "react";

import styles from "./styles.module.scss";

export const Auth = () => {
  const [username, password, formDisabled] = useUnit([
    $login,
    $password,
    $formDisabled,
  ]);

  const [isShow, setIsShow] = useState(false);

  return (
    <div className={styles.auth}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formSubmitted();
        }}
        name="auth"
      >
        <label>
          <Icon name="login-user" />

          <input
            className={styles.input}
            type="text"
            placeholder="Логин"
            onChange={(e) => loginChanged(e.target.value)}
            value={username}
          />
        </label>
        <label>
          <Icon name="login-password" />

          <input
            type={isShow ? "text" : "password"}
            className={styles.input}
            placeholder="Пароль"
            onChange={(e) => passwordChanged(e.target.value)}
            value={password}
          />
          {password && (
            <div
              className={styles.eye}
              onClick={() => {
                setIsShow(!isShow);
              }}
            >
              <Icon name="eye" />
            </div>
          )}
        </label>
        <label className={styles.remember}>
          <Checkbox className={styles.checkbox} />
          <span>Запомнить</span>
        </label>
        <button disabled={formDisabled} type="submit" className={styles.login}>
          <Icon name="enter" />
        </button>
      </form>
    </div>
  );
};
