import { $groupCalculation } from "@pages/settings/ui/payrolls/model/group-calculation.column";
import { $groupWageColumn } from "@pages/settings/ui/payrolls/model/group-wage.column";
import { $typesPayrollsColumn } from "@pages/settings/ui/payrolls/model/types-payrolls";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine(
  [$typesPayrollsColumn, $groupCalculation, $groupWageColumn],
  ([typesPayrollsColumn, groupCalculation, groupWageColumn]) => [
    typesPayrollsColumn,
    groupCalculation,
    groupWageColumn,
  ],
).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
