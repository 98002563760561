import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "select",
    placeholders: ["...", "...", undefined],
    values: [[], [], undefined],
    selects: [0, undefined, undefined],
    inputField: {
      value: "",
    },
  },
  {
    type: "select",
    placeholders: ["...", "...", undefined],
    values: [[], [], undefined],
    selects: [0, undefined, undefined],
    inputField: {
      value: "",
    },
  },
  {
    type: "select",
    placeholders: ["...", "...", undefined],
    values: [[], [], undefined],
    selects: [0, undefined, undefined],
    inputField: {
      value: "",
    },
  },
  {
    type: "select",
    placeholders: ["...", "...", undefined],
    values: [[], [], undefined],
    selects: [0, undefined, undefined],
    inputField: {
      value: "",
    },
  },
]).on(setFields, (_, field) => field);

export const $accrualsColumn = createStore<ColumnProps>({
  title: "Начисления",
  width: 458,
  renderFields: $fields.getState(),
  setFields: setFields,
  textAlign: "right",
  actionPlaceholder: "Выберите данные",
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "Начисления",
    deleteBlock: handleDeleteBlock,
  },
}).on($fields, (accrualsColumn, fields) => ({
  ...accrualsColumn,
  renderFields: fields,
}));
