import { RouteInstance } from "atomic-router";
import { useUnit } from "effector-react";

export const checkOpenedRoute = (
  route: RouteInstance<any> | RouteInstance<any>[],
) => {
  return Array.isArray(route)
    ? useUnit(route.map((route) => route.$isOpened)).some(Boolean)
    : useUnit(route.$isOpened);
};
