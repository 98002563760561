import { departmentModel } from "@entities/department";
import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "input",
    title: "ID",
    value: "",
  },
  {
    type: "input",
    title: "Название",
    value: "",
  },
  {
    type: "checkbox",
    title: "Активный",
    checked: false,
  },
  {
    type: "input",
    title: "Сортировка",
    value: "",
  },
]).on(setFields, (_, field) => field);

export const $groupColumn = createStore<ColumnProps>({
  title: "Группы",
  actionPlaceholder: "Выберите данные",
  renderFields: $fields.getState(),
  setFields: setFields,
  onFilter: () => {},
  onSave: () => {},
  options: departmentModel.$departments.getState(),
  context: {
    columnName: "Группы",
    deleteBlock: handleDeleteBlock,
  },
})
  .on($fields, (groupColumn, fields) => ({
    ...groupColumn,
    renderFields: fields,
  }))
  .on(departmentModel.$departments, (groupColumn, departments) => ({
    ...groupColumn,
    options: departments,
  }));
