export const formatPercentageInput = (numberInput: string | number): string => {
  const num =
    typeof numberInput === "string" ? parseFloat(numberInput) : numberInput;

  if (isNaN(num)) {
    return "";
  }

  return num.toFixed(2);
};
