import { Layout } from "@app/layout";
import { ComponentType } from "react";

export const withLayout =
  <T extends object>(Component: ComponentType<T>) =>
  (other: T) => (
    <Layout>
      <Component {...other} />
    </Layout>
  );
