import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const indexChanged = createEvent<number[]>();
export const $indexes = createStore<number[]>([]).on(
  indexChanged,
  (_, newIndex) => newIndex,
);

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "date",
    value: "2022-05-28",
  },
  {
    type: "date",
    value: "2021-11-28",
  },
  {
    type: "date",
    value: "2023-02-28",
  },
  {
    type: "date",
    value: "2024-03-28",
  },
  {
    type: "date",
    value: "2024-03-28",
  },
]).on(setFields, (_, field) => field);

export const $dateColumn = createStore<ColumnProps>({
  title: "Дата",
  width: 126,
  fieldHeight: 72,
  renderFields: $fields.getState(),
  setFields: setFields,
  actionHide: true,
  solidMod: true,
  isAlwaysOpen: true,
  colorAlternation: true,
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "Дата",
    deleteBlock: handleDeleteBlock,
  },
  headerFilter: {
    type: "date",
    list: $fields.getState().map((cell) => cell.value),
    selects: $indexes.getState(),
    setDates: indexChanged,
  },
})
  .on($fields, (dateColumn, fields) => ({
    ...dateColumn,
    renderFields: fields,
    headerFilter: {
      ...dateColumn.headerFilter,
      list: fields.map((cell) => cell.value),
    },
  }))
  .on($indexes, (dateColumn, indexes) => ({
    ...dateColumn,
    headerFilter: {
      ...dateColumn.headerFilter,
      selects: indexes,
    },
  }));
