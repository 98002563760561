import { sectionModel } from "@entities/section";
import { sessionModel } from "@entities/session";
import { Fallback } from "@pages/fallback";
import { Loader } from "@widgets/loader";
import { Sidebar } from "@widgets/nav/sidebar";
import { useUnit } from "effector-react";
import { motion } from "framer-motion";
import { PropsWithChildren, Suspense } from "react";

import styles from "./styles.module.scss";

export const Layout = ({ children }: PropsWithChildren) => {
  const [isAuth, pendingSession, pendingSection] = useUnit([
    sessionModel.$isAuth,
    sessionModel.$pending,
    sectionModel.$pending,
  ]);

  return (
    <>
      <Loader pending={pendingSession || pendingSection} />

      <motion.main
        className={styles.wrapper}
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0.5, delay: 0.5 },
        }}
        exit={{ opacity: 0, transition: { duration: 0.5, delay: 0.5 } }}
      >
        {isAuth && <Sidebar />}
        <Suspense fallback={<Fallback />}>{children}</Suspense>
      </motion.main>
    </>
  );
};
