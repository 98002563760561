import { CheckboxField } from "@features/column/fields/checkbox";
import { DateField } from "@features/column/fields/date";
import { IconField } from "@features/column/fields/icon";
import { InputField } from "@features/column/fields/input";
import { InputsField } from "@features/column/fields/inputs";
import { MonthInputField } from "@features/column/fields/month-input";
import { NumericalField } from "@features/column/fields/numerical-input";
import { SelectField } from "@features/column/fields/select";
import { TextAreaField } from "@features/column/fields/textarea";
import {
  ContentProps,
  Field,
  FieldHandler,
  Nullable,
  ValuesField,
} from "@shared/types";
import { Icon } from "@shared/ui";
import { columnModel } from "@widgets/column";
import { updateField } from "@widgets/column/lib/updateField";
import { Context } from "@widgets/context";
import clsx from "clsx";
import { useUnit } from "effector-react";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import RSC from "react-scrollbars-custom";

import styles from "./styles.module.scss";

const isNested = (columnType: ContentProps["columnType"]) =>
  columnType === "nested" || columnType === "nested_without_margin";

export const Content: FC<ContentProps> = ({
  renderFields,
  setFields,
  isSaveOpen,
  context,
  model,
  colorAlternation,
  solidMod,
  isAlwaysOpen,
  textAlign,
  fieldHeight,
  indexesToShow,
  columnType,
  useScrollY,
  rolled,
}) => {
  const [
    editingIndexSolid,
    editingOpenFieldIndex,
    fieldHeightSolid,
    pointerFields,
  ] = useUnit([
    columnModel.$editingIndex,
    columnModel.$editingOpenFieldIndex,
    columnModel.$fieldHeight,
    columnModel.$pointerFields,
  ]);
  const [editingIndex, setEditingIndex] = useState<Nullable<number>>(null);
  const parentDiv = useRef<HTMLDivElement>(null);
  const [openIndexes, setOpenIndexes] = useState<string[]>([]);

  const handleNestedFieldToggle = (index: string) => {
    const nestedIndexes: string[] = [];
    const isOpen = openIndexes.includes(index);
    if (isOpen) {
      nestedIndexes.push(index);
      const updateFields = (field: Field) => {
        field.sections &&
          field.sections.map((field) => {
            const currentIndex = `${field.title}${field.value}`;
            nestedIndexes.push(currentIndex);
            if (field.sections) {
              updateFields(field);
            }
          });
      };
      const findField = (fields: Field[]): Field | undefined => {
        for (const field of fields) {
          if (`${field.title}${field.value}` === index) {
            nestedIndexes.push(`${field.title}${field.value}`);
            updateFields(field);
            return field;
          }
          if (field.sections) {
            const foundField = findField(field.sections);
            if (foundField) {
              nestedIndexes.push(`${foundField.title}${foundField.value}`);
              return foundField;
            }
          }
        }
      };
      const currentField = findField(renderFields);
      currentField && updateFields(currentField);
      setOpenIndexes(openIndexes.filter((i) => !nestedIndexes.includes(i)));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  const fieldHandlers: Record<string, FieldHandler> = {
    input: (field: Field, _: number, isEditing) => (
      <InputField
        key={field.title}
        title={field.title as string}
        value={field.value as string}
        solidMod={solidMod}
        isEditing={isEditing}
        onChange={(value) =>
          updateField({
            field,
            setFields,
            fields: renderFields,
            value,
          })
        }
        textAlign={textAlign}
        noContext={field.noContext}
      />
    ),
    month_input: (field: Field, _: number, isEditing) => (
      <MonthInputField
        value={field.value as string}
        isEditing={isEditing}
        onChange={(value) =>
          updateField({
            field,
            setFields,
            fields: renderFields,
            value,
          })
        }
        textAlign={textAlign}
        solidMod={solidMod}
        noContext={field.noContext}
      />
    ),
    inputs: (field: Field, _: number, isEditing) => (
      <InputsField
        inputsValues={field.inputsValues}
        placeholders={field.placeholders}
        onChangeValues={(value: string, id: number) =>
          updateField({
            field,
            setFields,
            fields: renderFields,
            typeOfField: "inputs",
            value,
            index: id,
          })
        }
        isEditing={isEditing}
      />
    ),
    checkbox: (field: Field, _: number, isEditing) => (
      <CheckboxField
        key={field.title}
        title={field.title as string}
        checked={field.checked as boolean}
        isEditing={isEditing}
        onChange={(checked) =>
          updateField({
            field,
            setFields,
            fields: renderFields,
            typeOfField: "checkbox",
            value: checked,
          })
        }
      />
    ),
    icon: (_: Field, index: number, isEditing) => (
      <IconField key={index} isEditing={isEditing} />
    ),
    select: (field: Field, _: number, isEditing) => (
      <SelectField
        key={field.title}
        title={field.title as string}
        placeholders={field.placeholders}
        values={field.values as ValuesField}
        selects={field.selects}
        onChangeSelected={(value: number, id: number) =>
          updateField({
            field,
            setFields,
            fields: renderFields,
            typeOfField: "select",
            value,
            index: id,
          })
        }
        isEditing={isEditing}
        inputField={{
          value: field.inputField?.value,
          onChange: (value) =>
            updateField({
              field,
              setFields,
              fields: renderFields,
              typeOfField: "nested-input",
              value,
            }),
        }}
        textAlign={textAlign}
        solidMod={solidMod}
        noArrow={field.noArrow}
      />
    ),
    date: (field: Field, index: number, isEditing) => (
      <DateField
        key={index}
        onChange={(value) =>
          updateField({
            field,
            setFields,
            fields: renderFields,
            value,
          })
        }
        value={field.value as string}
        solidMod={solidMod}
        isEditing={isEditing}
        noContext={field.noContext}
      />
    ),
    numerical: (field: Field, _: number, isEditing) => (
      <NumericalField
        onChange={(value) =>
          updateField({
            field,
            setFields,
            fields: renderFields,
            value,
          })
        }
        value={field.value as string}
        solidMod={solidMod}
        isEditing={isEditing}
        textAlign={textAlign}
        noContext={field.noContext}
      />
    ),
    textarea: (field: Field, index: number, isEditing) => (
      <TextAreaField
        key={field.title}
        field={field}
        value={field.value as string}
        indexField={index}
        isEditing={isEditing}
        onChange={(value) =>
          updateField({
            field,
            setFields,
            fields: renderFields,
            value,
          })
        }
      />
    ),
    nested: (field: Field, index: number, _, level) => (
      <Fragment key={index}>
        <div
          onClick={() => handleNestedFieldToggle(field.title! + field.value)}
          className={clsx(styles.nested_field_title, {
            [styles.active]:
              openIndexes.includes(field.title! + field.value) &&
              field.sections &&
              field.sections.length > 0,
            [styles.bold]: field.sections && field.sections.length > 0,
          })}
          style={{ height: 58 }}
        >
          <div className={styles.nested_icon}>
            {field.icon && (
              <span>
                <Icon name={field.icon} />
              </span>
            )}
            <span>{field.title}</span>
          </div>
          <span>{field.value}</span>
        </div>
        {openIndexes.includes(field.title! + field.value) &&
          field.sections?.map((nestedField, nestedIndex) => (
            <div
              key={nestedIndex}
              style={{
                height: fieldHeight,
                marginLeft:
                  field.sections?.every((cell) => cell.type == "nested") &&
                  level != 0
                    ? level! * 15 + 15
                    : level! * 15,
              }}
              className={clsx(styles.nested_field, {
                [styles.field_in_nested]: nestedField.type != "nested",
              })}
            >
              {fieldHandlers[nestedField.type](
                nestedField,
                nestedIndex + index,
                index +
                  nestedIndex +
                  parseInt(field.value!.replace(/[ ,]/g, "")) +
                  1 ===
                  editingIndex,
                level! + 1,
                true,
                nestedIndex,
              )}
              <Context
                subName={
                  renderFields.find((field: any) => field.type === "select")
                    ?.title ?? "блок"
                }
                setEditingIndex={setEditingIndex}
                addBlock={context?.addBlock}
                copyBlock={context?.copyBlock}
                editingIndex={
                  nestedIndex +
                  parseInt(field.value!.replace(/[ ,]/g, "")) +
                  index +
                  1
                }
              />
            </div>
          ))}
      </Fragment>
    ),
  };

  useEffect(() => {
    setEditingIndex(null);
  }, [isSaveOpen, isAlwaysOpen]);

  if (!isSaveOpen && !isAlwaysOpen) {
    return null;
  }

  return (
    <RSC
      className={styles.content}
      noScrollX={true}
      noScrollY={!useScrollY}
      maximalThumbSize={60}
      minimalThumbSize={60}
    >
      <div
        className={clsx({
          [styles.fields]: columnType == undefined || columnType == "basic",
          [styles.nested_fields]: isNested(columnType),
          [styles.reset]: columnType == "nested_without_margin",
          [styles.alternation]: colorAlternation,
        })}
      >
        {renderFields.map(
          (field, index) =>
            (indexesToShow?.length === 0 ||
              indexesToShow === undefined ||
              indexesToShow?.includes(index)) && (
              <Fragment key={index}>
                {isNested(columnType) ? (
                  !rolled && (
                    <>
                      {fieldHandlers[field.type](
                        field,
                        index,
                        index + 1 === editingIndex,
                        0,
                        false,
                      )}

                      <Context
                        subName={
                          renderFields.find(
                            (field: any) => field.type === "select",
                          )?.title ?? "блок"
                        }
                        setEditingIndex={setEditingIndex}
                        addBlock={context?.addBlock}
                        copyBlock={context?.copyBlock}
                        editingIndex={index + 1}
                      />
                    </>
                  )
                ) : (
                  <div
                    style={{
                      cursor: pointerFields?.includes(field)
                        ? "pointer"
                        : "auto",
                      height:
                        (solidMod &&
                          index == editingOpenFieldIndex &&
                          (fieldHeightSolid > 71
                            ? fieldHeightSolid
                            : "71px")) ||
                        undefined,
                    }}
                    ref={parentDiv}
                    onClick={() =>
                      field.type == "textarea" &&
                      index + 1 != editingIndexSolid &&
                      columnModel.setOpenFieldIndex(index)
                    }
                    className={clsx(
                      {
                        [styles.field]: !solidMod && field.type !== "nested",
                      },
                      { [styles.solid_field]: solidMod },
                    )}
                  >
                    {!rolled &&
                      fieldHandlers[field.type](
                        field,
                        index,
                        index + 1 ===
                          (solidMod ? editingIndexSolid : editingIndex),
                        0,
                        false,
                      )}

                    {!field.noContext && (
                      <Context
                        subName={
                          renderFields.find(
                            (field: any) => field.type === "select",
                          )?.title ?? "блок"
                        }
                        setEditingIndex={
                          solidMod
                            ? columnModel.setEditingIndex
                            : setEditingIndex
                        }
                        addBlock={context?.addBlock}
                        copyBlock={context?.copyBlock}
                        editingIndex={index + 1}
                      />
                    )}
                  </div>
                )}
              </Fragment>
            ),
        )}
      </div>

      <Context
        model={model}
        columnName={context?.columnName}
        deleteBlock={context?.deleteBlock}
      />
    </RSC>
  );
};
