import { permissionsModel } from "@pages/settings/ui/permissions";
import { Column } from "@widgets/column";
import { useUnit } from "effector-react";

export const Permissions = ({}) => {
  const [columns] = useUnit([permissionsModel.$columns]);
  return (
    <>
      {columns.map((column) => (
        <Column
          key={column.title}
          {...column}
          context={column.context}
          model={permissionsModel}
        />
      ))}
    </>
  );
};
