import { useOutsideClick } from "@shared/hooks/outside-click";
import { HeaderFilter, Nullable } from "@shared/types";
import { Icon } from "@shared/ui";
import clsx from "clsx";
import { FC, useEffect, useRef, useState } from "react";

import styles from "./styles.module.scss";

export type PopUpProps = {
  list?: Nullable<any[]>;
  selects?: number[];
  setSelect?: (index: number) => void;
  popupRef: React.RefObject<HTMLDivElement>;
};

export const PopUp: FC<PopUpProps> = ({
  list,
  selects,
  setSelect,
  popupRef,
}) => {
  return (
    <div className={styles.select} ref={popupRef}>
      <div className={styles.scroll}>
        {list &&
          [...new Set(list)]
            .filter((cell) => cell !== "" && cell !== undefined)
            .sort((a, b) => a - b)
            .map((cell) => (
              <div
                className={clsx(styles.selector_item, {
                  [styles.select_active]: selects?.includes(cell),
                })}
                onClick={() => setSelect!(cell)}
              >
                {cell}
              </div>
            ))}
      </div>
    </div>
  );
};

export const MultiSelect: FC<HeaderFilter> = ({ list, selects, setSelect }) => {
  const [openDate, setOpenDate] = useState<boolean>(false);
  const deletePrevValue = () => {
    if (list && selects) {
      const difference = selects.filter((item) => !list.includes(item));
      return difference[0];
    }
  };
  useEffect(() => {
    if (deletePrevValue() != undefined) setSelect!(deletePrevValue() as number);
  }, [list]);
  const ref = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(event!.target as Node)
    ) {
      setOpenDate(false);
    }
  });
  return (
    <div className={clsx(styles.container, { [styles.active]: openDate })}>
      <div className={styles.output}>
        {selects?.map((number, index) => (
          <span key={index} className={styles.text}>
            {number}
            {index < selects?.length - 1 && ","}
          </span>
        ))}
        <span className={styles.text}>{selects?.length == 0 && "0"}</span>
      </div>

      <div
        ref={triggerRef}
        className={styles.open_trigger}
        onClick={() => setOpenDate(!openDate)}
      >
        <Icon name="triangle-down" />
      </div>

      {openDate && (
        <PopUp
          list={list}
          selects={selects}
          setSelect={setSelect}
          popupRef={ref}
        />
      )}
    </div>
  );
};
