import { Field } from "@shared/types";

interface updateFieldProps {
  setFields: (fields: Field[]) => void;
  fields: Field[];
  field: Field;
  typeOfField?: "input" | "inputs" | "checkbox" | "select" | "nested-input";
  index?: number;
  value: string | number | boolean;
}

export const updateField = ({
  setFields,
  fields,
  field,
  value,
  index,
  typeOfField = "input",
}: updateFieldProps): void => {
  const updatedFields = [...fields];
  const updateFunctions = {
    input: (field: Field) => {
      field.value = value as string;
    },
    checkbox: (field: Field) => {
      field.checked = value as boolean;
    },
    select: (field: Field) => {
      if (index !== undefined) {
        field.selects![index] = value as number;
      }
    },
    inputs: (field: Field) => {
      if (index !== undefined) {
        field.inputsValues![index] = value as string;
      }
    },
    "nested-input": (field: Field) => {
      if (field.inputField) {
        field.inputField.value = value as string;
      }
    },
  };
  const updateValue = (fields: Field[]) => {
    for (const currentField of fields) {
      if (currentField === field) {
        updateFunctions[typeOfField](currentField);
      } else if (currentField.type === "nested") {
        updateValue(currentField.sections || []);
      }
    }
  };
  updateValue(updatedFields);
  setFields(updatedFields);
};
