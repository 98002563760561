import { employeeModel } from "@pages/settings/ui/employee";
import { Column } from "@widgets/column";
import { useUnit } from "effector-react";

export const Employee = ({}) => {
  const [columns] = useUnit([employeeModel.$columns]);
  return (
    <>
      {columns.map((column) => (
        <Column
          key={column.title}
          {...column}
          context={column.context}
          model={employeeModel}
        />
      ))}
    </>
  );
};
