import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "select",
    placeholders: ["...", undefined, undefined],
    values: [[], undefined, undefined],
    selects: [0, undefined, undefined],
  },
]).on(setFields, (_, field) => field);

export const $specialPermissionsColumn = createStore<ColumnProps>({
  title: "Права особые",
  renderFields: $fields.getState(),
  setFields: setFields,
  width: 300,
  actionDisable: true,
  actionPlaceholder: "",
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "Права особые",
    deleteBlock: handleDeleteBlock,
  },
}).on($fields, (specialPermissionsColumn, fields) => ({
  ...specialPermissionsColumn,
  renderFields: fields,
}));
