import { ColumnProps, Field } from "@shared/types";
import { handleDeleteBlock } from "@widgets/context/libs/delete-block";
import { createEvent, createStore } from "effector";

export const indexChanged = createEvent<number[]>();
export const $indexes = createStore<number[]>([]).on(
  indexChanged,
  (_, newIndex) => newIndex,
);
export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "textarea",
    value: "Комиссия банка",
  },
  {
    type: "textarea",
    value: "Пополнение из платежей",
  },
  {
    type: "textarea",
    value: "Оплата курьера",
  },
  {
    type: "textarea",
    value: "Перевод денежных средств в кассу продакшна из кассы АК",
  },
  {
    type: "textarea",
    value:
      "Консультация юриста по перерасчету стоимости при досрочном расторжении договора",
  },
]).on(setFields, (_, field) => field);

export const $appointmentsColumn = createStore<ColumnProps>({
  isColumnMaybeDelete: true,
  title: "Назначение",
  width: "100vw",
  minWidth: 415,
  fieldHeight: 72,
  renderFields: $fields.getState(),
  setFields: setFields,
  actionHide: true,
  colorAlternation: true,
  solidMod: true,
  isAlwaysOpen: true,
  textAlign: "left",
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "Назначение",
    deleteBlock: handleDeleteBlock,
    copyBlock: () => {},
  },
  headerFilter: {
    type: "text-search",
    title: {
      title: "Баланс касса",
      value: "125 340,00",
    },
    secondTitle: {
      title: "Баланс карта",
      value: "- 1 1125 340,00",
    },
    list: $fields.getState().map((cell) => cell.value),
    selects: $indexes.getState(),
    setDates: indexChanged,
  },
})
  .on($fields, (appointmentsColumn, fields) => ({
    ...appointmentsColumn,
    renderFields: fields,
    headerFilter: {
      ...appointmentsColumn.headerFilter,
      list: fields.map((cell) => cell.value),
    },
  }))
  .on($indexes, (appointmentsColumn, indexes) => ({
    ...appointmentsColumn,
    headerFilter: {
      ...appointmentsColumn.headerFilter,
      selects: indexes,
    },
  }));
