import { ColumnProps, Field } from "@shared/types";
import { createEvent, createStore } from "effector";

export const setFields = createEvent<Field[]>();
export const $fields = createStore<Field[]>([
  {
    type: "nested",
    title: "Итого",
    value: "12 000 320,00",
    sections: [],
  },
  {
    type: "nested",
    title: "К выплате",
    value: "9 002 213,48",
    sections: [
      {
        type: "nested",
        title: "Зарплатный проект",
        value: "30 000,00",
        icon: "card",
        sections: [
          {
            type: "select",
            placeholders: ["...", "...", undefined],
            values: [[], [], undefined],
            selects: [0, undefined, undefined],

            inputField: {
              value: "",
            },
          },
          {
            type: "inputs",
            placeholders: ["...", "...", "..."],
            inputsValues: ["1", "2", "3"],
          },
        ],
      },
      {
        type: "nested",
        title: "Оклад",
        icon: "cash",
        value: "250 000,00",
        sections: [
          {
            type: "inputs",
            placeholders: ["...", "...", "..."],
            inputsValues: ["1", "2", "3"],
          },
        ],
      },
      {
        type: "nested",
        title: "Комиссия с продаж",
        value: "23 223,30",
        icon: "commission",
        sections: [
          {
            type: "inputs",
            placeholders: ["...", "...", "..."],
            inputsValues: ["1", "2", "3"],
          },
          {
            type: "inputs",
            placeholders: ["...", "...", "..."],
            inputsValues: ["1", "2", "3"],
          },
        ],
      },
      {
        type: "nested",
        title: "Новые клиенты",
        value: "23 223,30",
        icon: "new-file",
        sections: [
          {
            type: "inputs",
            placeholders: ["...", "...", "..."],
            inputsValues: ["1", "2", "3"],
          },
        ],
      },
      {
        type: "nested",
        title: "Тест-драйв",
        value: "2 000,00",
        icon: "productivity",
        sections: [
          {
            type: "select",
            placeholders: ["...", "...", undefined],
            values: [[], [], undefined],
            selects: [0, undefined, undefined],

            inputField: {
              value: "",
            },
          },
        ],
      },
      {
        type: "nested",
        title: "Премия за ролик",
        value: "123 339,23",
        icon: "volume",
        sections: [
          {
            type: "select",
            placeholders: ["...", "...", undefined],
            values: [[], [], undefined],
            selects: [0, undefined, undefined],

            inputField: {
              value: "",
            },
          },
        ],
      },
      {
        type: "nested",
        title: "Премия",
        value: "123 339,23",
        icon: "bonus",
        sections: [
          {
            type: "select",
            placeholders: ["...", "...", undefined],
            values: [[], [], undefined],
            selects: [0, undefined, undefined],

            inputField: {
              value: "",
            },
          },
        ],
      },
      {
        type: "nested",
        title: "Аванс",
        value: "9 002 213,48",
        icon: "like",
        sections: [
          {
            type: "select",
            placeholders: ["...", "...", undefined],
            values: [[], [], undefined],
            selects: [0, undefined, undefined],

            inputField: {
              value: "",
            },
          },
        ],
      },
    ],
  },
]).on(setFields, (_, field) => field);

export const $productionColumn = createStore<ColumnProps>({
  title: "Продакш",
  width: 358,
  renderFields: $fields.getState(),
  isAlwaysOpen: true,
  actionDisable: true,
  actionStatic: {
    icon: "login-user",
    text: "01.2022 - 09.2022",
    title: "Продакшн",
  },
  columnType: "nested_without_margin",
  setFields: setFields,
  onFilter: () => {},
  onSave: () => {},
  options: [],
  context: {
    columnName: "Продакш",
  },
}).on($fields, (productionColumn, fields) => ({
  ...productionColumn,
  renderFields: fields,
}));
