import clsx from "clsx";
import { ChangeEvent, FC } from "react";

import styles from "./styles.module.scss";

type InputFieldProps = {
  title?: string;
  value: string;
  onChange: (newValue: string, columnIndex: number) => any;
  isEditing?: boolean | undefined;
  columnIndex?: number;
  className?: string;
  solidMod?: boolean;
  noContext?: boolean;
};

export const DateField: FC<InputFieldProps> = ({
  title,
  value,
  onChange,
  isEditing,
  columnIndex,
  className,
  solidMod,
  noContext,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(e.target.value, columnIndex as number);
  return (
    <div
      className={clsx(
        { [styles.field]: !solidMod },
        { [styles.solid_field]: solidMod },
        className,
      )}
    >
      {title && <span className={styles.text}>{title}</span>}
      {title && <div className={styles.separator} />}
      <div
        className={clsx(styles.input, isEditing && styles.border, className)}
      >
        <input
          type="date"
          className={styles.input_data}
          onChange={handleChange}
          value={value}
          min="1000-01-01"
          max="3000-12-31"
          disabled={noContext ? false : !isEditing}
          autoFocus={isEditing}
          style={{
            zIndex: isEditing || noContext ? 2 : 0,
            textAlign: solidMod ? "center" : "right",
          }}
        />
      </div>
    </div>
  );
};
