import { ColumnProps } from "@shared/types";

export const getSelectedIndices = (column: ColumnProps) => {
  if (column.headerFilter?.type === "select" && column.headerFilter.selects) {
    const selectedValues = column.headerFilter.selects;
    const renderFields = column.renderFields;
    return renderFields
      .map((field, index) =>
        selectedValues.includes(field.value as any) ? index : null,
      )
      .filter((index) => index !== null);
  }
  if (
    column.headerFilter?.type === "double-radix-select" &&
    column.headerFilter.selects
  ) {
    const selectedValues = column.headerFilter.selects;
    const selectedIndex = column.headerFilter.list
      ?.map((item, index) => (selectedValues.includes(item) ? index : -1))
      .filter((index) => index !== -1);

    if (selectedIndex && selectedIndex.length > 0) {
      const renderFields = column.renderFields;
      const filteredRenderFields = renderFields
        .map((field, index) =>
          selectedIndex.includes(field.selects![0] as any) ? index : null,
        )
        .filter((index) => index !== null);
      return filteredRenderFields;
    }
  }
  if (
    (column.headerFilter?.type === "date" ||
      column.headerFilter?.type === "period" ||
      column.headerFilter?.type === "numerical-search" ||
      column.headerFilter?.type === "text-search") &&
    column.headerFilter.selects
  ) {
    const selectedValues = column.headerFilter.selects;

    return selectedValues;
  }

  return [];
};
