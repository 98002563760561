import { $allNetworkColumn } from "@pages/finance/ui/calculation/model/all-network.column";
import { $foodColumn } from "@pages/finance/ui/calculation/model/food.column";
import { $grandColumn } from "@pages/finance/ui/calculation/model/grand.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine(
  [$allNetworkColumn, $grandColumn, $foodColumn],
  ([allNetworkColumn, grandColumn, foodColumn]) => [
    allNetworkColumn,
    grandColumn,
    foodColumn,
  ],
).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
