import { Column } from "@widgets/column";
import { Menu } from "@widgets/menu";
import { useUnit } from "effector-react";
import { useRef } from "react";

import { calculationModel } from "../index";
import styles from "./styles.module.scss";

export const Calculation = () => {
  const [columns] = useUnit([calculationModel.$columns]);
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Menu menu="calculation" />

      <div
        className={styles.scroll}
        ref={scrollRef}
        onWheel={(event) => {
          const container = scrollRef.current;
          if (container && event.clientY <= 120) {
            container.scrollLeft += event.deltaY;
          }
        }}
      >
        {columns.map((column) => (
          <Column
            key={column.title}
            {...column}
            context={column.context}
            model={calculationModel}
            width={380}
          />
        ))}
      </div>
    </>
  );
};
