import { $employeeColumn } from "@pages/settings/ui/employee/model/employee.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine([$employeeColumn], ([employeeColumnValue]) => [
  employeeColumnValue,
]).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
