import { App } from "@app/app";
import { appStarted } from "@shared/config";
import ReactDOM from "react-dom/client";

const rootElement = document.getElementById("app");

if (!rootElement) {
  throw new Error("No root element found!");
}

appStarted();

ReactDOM.createRoot(rootElement).render(<App />);
