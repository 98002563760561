import { Checkbox } from "@shared/ui";
import clsx from "clsx";
import { FC } from "react";

import styles from "./styles.module.scss";

type CheckboxFieldProps = {
  title: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  isEditing?: boolean;
};

export const CheckboxField: FC<CheckboxFieldProps> = ({
  title,
  checked,
  onChange,
  isEditing,
}) => {
  const handleChange = () => onChange && onChange(!checked);
  return (
    <div className={styles.field}>
      <span className={styles.text}>{title}</span>
      <div className={styles.separator} />
      <div className={clsx(styles.checkbox, { [styles.border]: isEditing })}>
        <Checkbox checked={checked} onCheckedChange={handleChange} />
      </div>
    </div>
  );
};
