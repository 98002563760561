import { $alpenovColumn } from "@pages/finance/ui/wage/model/alpernov.column";
import { $productionColumn } from "@pages/finance/ui/wage/model/production.column";
import { $rafalskayaColumn } from "@pages/finance/ui/wage/model/rafalskaya.column";
import { ColumnProps } from "@shared/types";
import { combine, createEvent, createStore } from "effector";

export const $columns = createStore<ColumnProps[]>([]);

export const setColumns = createEvent<ColumnProps[]>();

export const addColumn = createEvent<ColumnProps>();

$columns
  .on(setColumns, (_, newColumns) => newColumns)
  .on(addColumn, (columns, newColumn) => [...columns, newColumn]);
combine(
  [$productionColumn, $alpenovColumn, $rafalskayaColumn],
  ([productionColumn, alpernovColumn, rafalskayaColumn]) => [
    productionColumn,
    alpernovColumn,
    rafalskayaColumn,
  ],
).watch((combinedColumns) => {
  setColumns(combinedColumns);
});
